import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  getDeliveryOptions,
  setAddToCartValidationErrors
} from "../../../../redux/actions/productAction";
import {
  getDistanceBetweenTwoCoords,
  usePrevious
} from "../../../../functions/Utilities";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";

import "./Styles/VendorList.css";

const WIGGLE_TIMER = 2500;

const VendorList = ({ handleEnquiryModalOpenClicked }) => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = React.useContext(I18nContext);

  const [supplierState, setSupplierState] = React.useState([]);
  const [initialSupplierState, setInitialSupplierState] = React.useState([]);
  const [addToCartNumber, setAddToCartNumber] = React.useState({});
  const [wiggleState, setWiggleState] = useState([]);
  const prevAddToCartNumber = usePrevious(addToCartNumber);
  const [supplierPrice, setSupplierPrice] = React.useState({});
  const [supplierInventory, setSupplierInventory] = useState({});
  const [selectedDropdownValues, setSelectedDropdownValues] = React.useState({
    name: "--all--",
    city: "--all--",
    distid: "--all--"
  });

  /*   const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );
 */

  /*   React.useEffect(() => {
    if (Object.keys(skuPriceState).length > 0) {
      if (skuPriceState.prices.length > 0)
        setPrice(skuPriceState.prices[0].price_1);
    } else if (Object.keys(priceState).length > 0) {
      setPrice(priceState.prices[0].price_1);
    }
  }, [priceState, skuPriceState]); */

  const { lat: latitudeState, lng: longitudeState } = useSelector(
    state => state.loginReducer.userInfo,
    shallowEqual
  );

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );
  const priceInventoryState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  useEffect(() => {
    if (Object.keys(priceInventoryState).length > 0) {
      let tempPrices = {};
      priceInventoryState.prices.forEach(supp => {
        tempPrices = { ...tempPrices, [supp.distributorId]: supp.price_1 };
      });
      setSupplierPrice(tempPrices);
      let tempInventory = {};
      priceInventoryState.invs.forEach(inv => {
        tempInventory = { ...tempInventory, [inv.distributorId]: inv.instock };
      });

      setSupplierInventory(tempInventory);
    }
  }, [priceInventoryState]);

  useEffect(() => {
    if (
      supplierInfoState.length > 0 &&
      priceInventoryState &&
      priceInventoryState.invs
    ) {
      let supp = [...supplierInfoState[0].distributorOrder];
      supp.shift();

      let invDistIds = priceInventoryState.invs.reduce((p, inv) => {
        return [...p, inv.distributorId];
      }, []);
      supp = supp.filter(sup => invDistIds.includes(sup.distid));
      supp.forEach(sup => {
        sup.distance = getDistanceBetweenTwoCoords(
          sup.lat,
          sup.longitude,
          latitudeState,
          longitudeState
        );
      });
      supp.sort((a, b) => a.distance - b.distance);
      setSupplierState(supp);
      setInitialSupplierState(supp);
      let countStarter = {};
      supplierInfoState[0].distributorOrder.map(sup => {
        countStarter = { ...countStarter, [sup.distid]: 1 };
      });
      setAddToCartNumber(countStarter);
    }
  }, [supplierInfoState, priceInventoryState]);

  useEffect(() => {
    let timer = null;
    if (
      Object.keys(addToCartNumber).length > 0 &&
      Object.keys(prevAddToCartNumber).length > 0
    ) {
      let differences = [];

      for (let key of Object.keys(addToCartNumber)) {
        if (addToCartNumber[key] != prevAddToCartNumber[key]) {
          differences.push(key);
        }
      }

      timer = setTimeout(() => {
        setWiggleState([...differences]);
      }, WIGGLE_TIMER);
    }

    return () => {
      setWiggleState([]);
      clearTimeout(timer);
    };
  }, [addToCartNumber]);

  const handleAddToCart = (distributorId, quoteMode = false) => {
    let attributesObject = null;

    let requiredFields = [];

    if (
      selectedProductAttributesState.hasOwnProperty(
        mainItemIdState || itemIdState
      )
    ) {
      attributesObject =
        selectedProductAttributesState[mainItemIdState || itemIdState];

      let expectedAttributes = attributeDetailsState.reduce((p, c) => {
        const { screenname, attributeid } = c;
        p = [...p, { screenname, attributeid }];
        return p;
      }, []);

      let receivedAttributeIds = Object.keys(attributesObject);

      if (mainItemIdState != 0) {
        expectedAttributes.forEach(attr => {
          if (!receivedAttributeIds.includes(attr.attributeid.toString()))
            requiredFields.push(attr.screenname);
        });
      } else {
        expectedAttributes.forEach(attr => {
          requiredFields.push(attr.screenname);
        });
      }
    } else {
      if (attributeDetailsState && attributeDetailsState.length > 0) {
        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid } = c;
          p = [...p, { screenname, attributeid }];
          return p;
        }, []);

        expectedAttributes.forEach(attr => {
          requiredFields.push(attr.screenname);
        });
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));

    if (requiredFields.length > 0) {
      return;
    }

    dispatch(
      getDeliveryOptions(
        distributorId,
        supplierInfoState[0].code,
        addToCartNumber[distributorId],
        supplierInfoState[0].itemid,
        attributesObject,
        quoteMode
      )
    );
  };

  /*  const handleAddToCart = distributorId => {
    let attributesObject = null;
    if (
      mainItemIdState &&
      mainItemIdState != 0 &&
      selectedProductAttributesState &&
      selectedProductAttributesState.length > 0
    ) {
      attributesObject = selectedProductAttributesState[mainItemIdState];
    }
    let supplierName = supplierInfoState[0].distributorOrder.filter(
      dist => dist.distid == distributorId
    ).name;

    dispatch(
      getDeliveryOptions(
        distributorId,
        supplierInfoState[0].code,
        addToCartNumber[distributorId],
        supplierInfoState[0].itemid,
        attributesObject
      )
    );

    dispatch(
      addToLocalMiniCart(
        productInitialStateFromFetchTitle,
        price,
        addToCartNumber[distributorId],
        supplierName
      )
    );
  };

  const handleAddToQuote = distributorId => {
    let attributesObject = null;
    if (
      mainItemIdState &&
      mainItemIdState !== 0 &&
      selectedProductAttributesState
    ) {
      if (selectedProductAttributesState.hasOwnProperty(mainItemIdState)) {
        attributesObject = selectedProductAttributesState[mainItemIdState];
      }
    }

    let supplierName = supplierInfoState[0].distributorOrder.filter(
      dist => dist.distid == distributorId
    ).name;

    dispatch(
      getDeliveryOptions(
        distributorId,
        supplierInfoState[0].code,
        addToCartNumber[distributorId],
        supplierInfoState[0].itemid,
        attributesObject,
        true
      )
    );

    dispatch(
      addToLocalMiniCart(
        productInitialStateFromFetchTitle,
        price,
        addToCartNumber[distributorId],
        supplierName
      )
    );
  }; */

  const onVarientsDropdownChange = (event, key) => {
    const { value } = event.target;
    if (value == "--all--") {
      setSupplierState(initialSupplierState);
    } else {
      setSupplierState(supplierState.filter(sup => sup[key] == value));
    }
    setSelectedDropdownValues({ ...selectedDropdownValues, [key]: value });
  };

  if (supplierState.length > 0) {
    return (
      <div
        className="also-available-container"
        style={{ backgroundColor: "white" }}
      >
        <div className="also-available-wrapper">
          <h2 className="also-available-header">
            {translate("js.item.alsoavailable")}
          </h2>
          <div
            style={{ paddingTop: "7px" }}
            className="itemDistributors"
            id="js-item-distributors-294311"
          >
            <table
              id="myTable"
              border="1"
              className="table table-bordered table-striped ddtf-processed"
            >
              <thead>
                <tr>
                  <th>
                    <div style={{ display: "none" }}>
                      {translate("js.item.seller")}
                    </div>
                    <select
                      defaultValue={selectedDropdownValues["name"]}
                      onChange={event =>
                        onVarientsDropdownChange(event, "name")
                      }
                      className="form-control varients_dropdown"
                    >
                      <option value="--all--">
                        {translate("js.item.seller")}
                      </option>
                      {supplierState.length > 0
                        ? supplierState.map((sup, index) => (
                            <option key={index} value={sup.name}>
                              {sup.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </th>
                  <th>
                    <div style={{ display: "none" }}>
                      {translate("js.item.location")}
                    </div>
                    <select
                      defaultValue={selectedDropdownValues["city"]}
                      onChange={event =>
                        onVarientsDropdownChange(event, "city")
                      }
                      className="form-control varients_dropdown"
                    >
                      <option value="--all--">
                        {translate("js.item.location")}
                      </option>
                      {supplierState.length > 0
                        ? supplierState.map((sup, index) => (
                            <option key={index} value={sup.city}>
                              {`${sup.city} (${Math.ceil(sup.distance)} km)`}
                            </option>
                          ))
                        : null}
                    </select>
                  </th>
                  <th>Seller Rating </th>
                  <th>
                    <div style={{ display: "none" }}>
                      {translate("js.item.price")}
                    </div>
                    <select
                      defaultValue={selectedDropdownValues["distid"]}
                      onChange={event =>
                        onVarientsDropdownChange(event, "distid")
                      }
                      className="form-control varients_dropdown"
                    >
                      <option value="--all--">
                        {translate("js.item.price")}
                      </option>
                      {supplierState.length > 0
                        ? supplierState.map(sup => {
                            return (
                              <FormattedNumber
                                key={sup.distid}
                                value={supplierPrice[sup.distid] / priceConvert}
                                style="currency"
                                currency={currency}
                                children={result => (
                                  <option value={sup.distid}>{result}</option>
                                )}
                              ></FormattedNumber>
                            );
                          })
                        : null}
                    </select>
                  </th>
                  <th>{translate("js.item.stock")}</th>
                  <th>{translate("js.item.quantity")}</th>
                  <th>{translate("js.item.cart")}</th>
                  {process.env.REACT_APP_DISPLAY_STOCK ? (
                    <th>{translate("js.item.quote")}</th>
                  ) : null}
                  <th>{translate("js.item.enquiry")}</th>
                </tr>
              </thead>
              <tbody>
                {supplierState.length > 0
                  ? supplierState.map(sup => (
                      <tr key={sup.distid}>
                        <td>{sup.name}</td>
                        <td>{`${sup.city} (${Math.ceil(sup.distance)} km)`}</td>
                        <td>
                          <div className="dist-item-rating-206 text-center">
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <br />
                            <div className="distReviewCount">
                              0 {translate("js.item.reviews")}
                            </div>
                          </div>
                        </td>
                        <td className="vendor-list-price-column">
                          <FormattedNumber
                            value={supplierPrice[sup.distid] / priceConvert}
                            style="currency"
                            currency={currency}
                          />
                          {/* <span className="symbol">
                            {currencyState.sign && currencyState.sign}
                          </span>
                          <span className="dollars">
                            {supplierPrice[sup.distid]}
                          </span>
                  {currencyState.code && currencyState.code}*/}
                        </td>
                        {process.env.REACT_APP_DISPLAY_STOCK === "true" ? (
                          <td>
                            <div className="inStock-column">
                              <span>{supplierInventory[sup.distid]}</span>
                            </div>
                          </td>
                        ) : null}
                        <td>
                          <div className="qtyControlsBox">
                            <div className="qtyControlsBtns">
                              <div className="qtyControlsPlus">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setAddToCartNumber({
                                      ...addToCartNumber,
                                      [sup.distid]:
                                        addToCartNumber[sup.distid] + 1
                                    })
                                  }
                                >
                                  +
                                </span>
                              </div>
                              <div className="qtyControlsMinus">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (addToCartNumber[sup.distid] - 1 > 0) {
                                      setAddToCartNumber({
                                        ...addToCartNumber,
                                        [sup.distid]:
                                          addToCartNumber[sup.distid] - 1
                                      });
                                    }
                                  }}
                                >
                                  -
                                </span>
                              </div>
                            </div>
                            <input
                              className="qtyControlsInput"
                              type="text"
                              size={String(addToCartNumber[sup.distid]).length}
                              value={addToCartNumber[sup.distid]}
                              onChange={e =>
                                setAddToCartNumber({
                                  ...addToCartNumber,
                                  [sup.distid]: Number(e.target.value)
                                })
                              }
                            />
                            <div className="clearfix"></div>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`addToCartBtn cartBtn${
                              wiggleState.includes(String(sup.distid))
                                ? " wiggle"
                                : ""
                            }`}
                            title={translate("js.item.addtocart")}
                            onClick={() => handleAddToCart(sup.distid, false)}
                          >
                            <span>
                              <i className="material-icons add-icon">
                                add_shopping_cart
                              </i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className="addToCartBtn"
                            title={translate("js.item.addtoquote")}
                            onClick={() => handleAddToCart(sup.distid, true)}
                          >
                            <span>
                              <i className="material-icons add-icon">
                                description
                              </i>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            id="enquiry-206"
                            className="addToCartBtn sendEnquiryBtn"
                            title={translate("js.item.enquiry")}
                            onClick={() => handleEnquiryModalOpenClicked()}
                          >
                            <span>
                              <i className="material-icons add-icon">
                                mail_outline
                              </i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default VendorList;
