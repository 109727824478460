import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PROJECT_LINK, PREVIEW } from "../../../project-config";
import classes from "../Styles/WishListMobile.module.css";
import { Link } from "react-router-dom";
import history from "../../../history.js";
import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
import { unMountProductPageAction } from "../../../redux/actions/productAction";

export default function WishListMobile({ show, close, toggleWish }) {
  const dispatch = useDispatch();
  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const { langCode, currency, translate, priceConvert } = React.useContext(
    I18nContext
  );

  const renderWishList = () => {
    if (wishListState && wishListState.length > 0) {
      return wishListState.map(wishItem => renderWishListItem(wishItem));
    } else {
      return (
        <p className={classes.noItemsFound}>
          {translate("js.header.wishlist.noitems")}
        </p>
      );
    }
  };

  const handleWishItemClicked = url => {
    close();
    dispatch(unMountProductPageAction());
    url = url.replace("//", "/");
    history.push(url);
  };

  const renderWishListItem = wishItem => {
    let { id, code, title, desc, currency_sign, image, price, url } = wishItem;
    url = `${PREVIEW}/${url.replace("product/", "")}`;
    let description = desc && desc.replace('\\"', '"');
    return (
      <div
        className={classes.wishItemWrapper}
        onClick={() => handleWishItemClicked(url)}
      >
        <div className={classes.wishItemImage}>
          <img src={image} alt={`${title} Image`} />
        </div>
        <div className={classes.wishItemDetails} style={{ overflow: "hidden" }}>
          <div className={classes.wishItemDesc}>{description}</div>
          <div className={classes.wishItemCode}>
            <label>Code:</label>
            <span>{code}</span>
          </div>
          <div className={classes.wishItemPrice}>
            <FormattedNumber
              value={
                Number(price.value.integer + "." + price.value.decimal) /
                priceConvert
              }
              style="currency"
              currency={currency}
            />
          </div>
          <div className={classes.wishItemActionWrapper}>
            <button
              className={classes.wishItemRemove}
              onClick={e => {
                e.stopPropagation();
                toggleWish(
                  e,
                  id,
                  code,
                  title,
                  desc,
                  currency_sign,
                  image,
                  price,
                  url
                );
              }}
            >
              {translate("wishlistMobile.removeButtonText")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={close}
      className={
        `${langCode === "ar" ? classes.arabic + " " : ""}` +
        classes.outerContainer +
        `${show ? " " + classes.active : ""}`
      }
    >
      <div
        className={classes.container + `${show ? " " + classes.active : ""}`}
      >
        <div
          className={
            langCode === "ar"
              ? "wishListTitleArabic " + classes.wishListTitle
              : classes.wishListTitle
          }
        >
          <h3>{translate("wishlistMobile.title")}</h3>
          <i className="material-icons" onClick={close}>
            close
          </i>
        </div>

        <div className={classes.wrapper}>
          <div className={classes.wishListWrapper + ` scroll-bar-thin-style`}>
            {renderWishList()}
          </div>
        </div>
      </div>
    </div>
  );
}
