import React from "react";
import { PROJECT_LINK, VID } from "../project-config";
import { I18nContext } from "../i18n/index";
import MenuTopBar from "../header/MenuTopBar";
import MerchantRegisterForm from "../content/MerchantRegisterForm";
import Footer from "../components/AC-Footer/Footer";
import Async from "react-code-splitting";


const LocationBar = () => (
  <Async load={import("../components/AC-Location/LocationBar.jsx")} />
);


const MerchantRegister = () => {
  

  return (
    <>
      <MenuTopBar />
      <LocationBar />
      <MerchantRegisterForm />
      <Footer />
    </>
  );
};

export default MerchantRegister;
