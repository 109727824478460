import React, { useContext } from "react";
import CookieBtn from "./CookieBtn";
import { Link } from "react-router-dom";
import { I18nContext } from "../../i18n";

function CookieDialog(props) {
  const { translate } = useContext(I18nContext);
  return (
    <div className="cookie-dialog">
      <p>
        {translate("cookieDialog.paragraph")}&nbsp;
        <Link to={props.destination}>
          <u style={{ color: "rgb(213, 213, 213)" }}>
            {translate("cookiePage.manageCookies")}
          </u>
        </Link>
      </p>
      <div className="cookie-button">
        <CookieBtn click={props.acceptCookieCloseDialog}>
          {translate("cookieDialog.accept")}
        </CookieBtn>
      </div>
    </div>
  );
}

export default CookieDialog;
