import { call, put } from "redux-saga/effects";
import {
  GET_ITEM_FETCH_REQUEST,
  GET_ITEM_FETCH_SUCCESS,
  GET_ITEM_FETCH_FAILURE,
  UPDATE_PRICE_INVENTORY,
  SUPPLIER_INFO_SUCCESS,
  ADD_TO_CART_MODAL_CLOSE,
  CHANGE_PRODUCT_ATTRIBUTES,
  CHANGE_TITLE_AND_LONGDESC,
  REQUEST_BASKET_AFTER_ADDING_TO_CART,
  SHOW_CONTINUE_MODAL,
  UNMOUNT_PRODUCT_PAGE,
  SET_REVIEW_MODAL_STATE,
  SHOW_CONTINUE_MODAL_QUOTE,
  SUCECSS_MAIN_PRODUCT_SKUS_N_SKUIDS,
  FAILURE_MAIN_PRODUCT_SKUS_N_SKUIDS,
  REQUEST_MAIN_PRODUCT_SKUS_N_SKUIDS,
  SET_ATTRIBUTES_DETAILS,
  SET_CART_VALIDATION_ERR,
  POPULATE_ACCESSORY_MODAL,
  CLOSE_ACCESSORY_MODAL,
  GET_MODAL_ITEM_FETCH_REQUEST,
  GET_MODAL_ITEM_FETCH_SUCCESS,
  deliveryOptionsActions,
  addToCartActions,
  UPDATE_ACCESSORY_INVENTORY,
  SET_SELECTED_CHECKBOX_ITEMS,
  SET_PRODUCT_SWITCH_IMAGE_FLAG,
  SET_PRODUCT_IMAGECAROUSEL_INITIAL,
  SET_PRODUCT_OUT_OF_STOCK_ERR
} from "../types.js";

import {
  GET_ITEM_LINK,
  GET_ID_PRODUCT_LINK,
  GET_PRICE_INVENTORY,
  ADD_TO_CART,
  GET_DELIVERY_OPTIONS,
  GET_SUPPLIER_INFO,
  ADD_TO_SUPP_CART
} from "../links.js";

import { VID, PREVIEW } from "../../project-config";

import { store } from "../../index";

import marketplaces from "../../marketplaces";

export const unMountProductPageAction = () => ({
  type: UNMOUNT_PRODUCT_PAGE
});

/**
 * @param {boolean} payload           True or false.
 */
export const setProductSwitchImageAction = payload => ({
  type: SET_PRODUCT_SWITCH_IMAGE_FLAG,
  payload: payload
});

export const setProductImagecarouselInitialAction = payload => ({
  type: SET_PRODUCT_IMAGECAROUSEL_INITIAL,
  payload: payload
});

export const setAddToCartValidationErrors = payload => ({
  type: SET_CART_VALIDATION_ERR,
  payload: payload
});

export const setProductOutOfStockError = payload => ({
  type: SET_PRODUCT_OUT_OF_STOCK_ERR,
  payload: payload
});

export const setAttributesDetailsAction = payload => ({
  type: SET_ATTRIBUTES_DETAILS,
  payload: payload
});

export const setReviewModalStateAction = payload => ({
  type: SET_REVIEW_MODAL_STATE,
  payload: payload
});

export const fetchingProductRequestSaga = id => ({
  type: GET_ITEM_FETCH_REQUEST,
  payload: { id }
});

export const addToCartModalClose = () => ({
  type: ADD_TO_CART_MODAL_CLOSE
});

export const updateSelectedCheckboxItems = (item, mode) => ({
  type: SET_SELECTED_CHECKBOX_ITEMS,
  payload: { item, mode }
});

const api = link =>
  fetch(link)
    .then(res => res.json())
    .then(json => {
      return {
        json: json.__Result[0]
      };
    });

export function* fetchFunctionSaga(action) {
  let language = store.getState().mainReducer.lang;
  try {
    let link = GET_ITEM_LINK;

    link = link
      .replace("$ITEMREPLACE", action.payload.id)
      .replace("$LANGUAGE", language);

    const product = yield call(api, link);

    yield put(fetchingItemSuccess(product.json, action.payload.id));
  } catch (e) {
    console.error("ERROR FETCH PRODUCT: ", e.message);
    fetchingItemFailure(e);
  }
}

export const fetchingItemSuccess = (json, id) => ({
  type: GET_ITEM_FETCH_SUCCESS,
  payload: { json, id }
});

export const fetchingItemFailure = error => ({
  type: GET_ITEM_FETCH_FAILURE,
  payload: error
});

export const changeTitleAndLongDesc = json => ({
  type: CHANGE_TITLE_AND_LONGDESC,
  payload: json
});

export const fetchingProductPriceInventory = id => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    fetch(
      GET_PRICE_INVENTORY.replace("$PRODUCT", id).replace("$LANGUAGE", language)
    )
      .then(res => res.json())
      .then(json =>
        dispatch({ type: UPDATE_PRICE_INVENTORY, payload: json.__Result[0] })
      );
  };
};

export const fetchMainProductSkusAndSkuIds = id => {
  let language = store.getState().mainReducer.lang;

  let link = GET_ITEM_LINK;

  link = link.replace("$ITEMREPLACE", id).replace("$LANGUAGE", language);
  return dispatch => {
    dispatch({ type: REQUEST_MAIN_PRODUCT_SKUS_N_SKUIDS });
    fetch(link)
      .then(res => res.json())
      .then(json => {
        let skus = json.__Result[0].skus;
        let skuIds = json.__Result[0].skuids;
        dispatch({
          type: SUCECSS_MAIN_PRODUCT_SKUS_N_SKUIDS,
          payload: { mainProductSkus: skus, mainProductSkuIds: skuIds }
        });
      })
      .catch(err => {
        dispatch({ type: FAILURE_MAIN_PRODUCT_SKUS_N_SKUIDS });
      });
  };
};

export const reFetchProductInitialState = (link, itemid = null) => {
  if (itemid) {
    let language = store.getState().mainReducer.lang;

    link = ADD_TO_CART.replace("$PRODUCT", itemid).replace(
      "$LANGUAGE",
      language
    );
  }
  return dispatch => {
    fetch(link)
      .then(res => res.json())
      .then(json => {
        console.info("product json", json);

        json = json && json[0];
        json.productLink = link;

        // Filtering out the reviews where the rating is 0
        if (json && json.reviews && json.reviews.length > 0) {
          json.reviews = json.reviews.filter(review => review.rating != 0);
        }
        console.info("product json others");
        dispatch(changeTitleAndLongDesc(json));
      })
      .catch(err => {
        console.error("ERROR REFETCH INITIAL: ", err.message);
        dispatch(fetchingItemFailure(err));
      });
  };
};

export const fetchDirectUrlGetItem = (url, langCode, countryCode) => {
  console.info("new url4", url, langCode);
  let tempUrl = url;
  return dispatch => {
    tempUrl = tempUrl.includes("preview")
      ? tempUrl.replace("/preview", "")
      : tempUrl;

    if (!tempUrl.includes("product")) {
      if (tempUrl.includes(`/${langCode}/`)) {
        tempUrl = tempUrl.replace(`/${langCode}`, "");
      }
      if (marketplaces.some(market => tempUrl.includes(market[1]))) {
        let marketplaceName = marketplaces.filter(market => {
          if (tempUrl.includes(market[1])) {
            return true;
          } else {
            return false;
          }
        })[0][1];
        tempUrl = tempUrl.replace(`/${marketplaceName}`, "");
        tempUrl = `/${marketplaceName}/product${tempUrl}`;
      } else {
        tempUrl = "/product" + tempUrl;
      }
    }
    let id = "";
    console.info("GIRDI WTF0", GET_ID_PRODUCT_LINK, tempUrl);

    let link = GET_ID_PRODUCT_LINK.replace("$PRODUCT", tempUrl).replace(
      "$LANGUAGE",
      langCode
    );

    if (link.includes(`/${langCode}/`)) {
      link = link.replace(`${langCode}/`, "");
    }

    console.info("new url4", link);

    if (tempUrl.includes("storeitem.html")) {
      console.info("GIRDI WTF1");
      id = tempUrl.split("iid=")[1];
      dispatch(fetchingProductRequestSaga(id));
      dispatch(fetchingProductPriceInventory(id));
    } else {
      console.info("GIRDI WTF2", link);

      fetch(link)
        .then(res => {
          //return res.text();
          return res.json();
        })
        .then(json => {
          console.info("product json", json);
          json = json && json[0];
          id = json.id;

          json.productLink = link;
          // Filtering out the reviews where the rating is 0
          if (json && json.reviews && json.reviews.length > 0) {
            json.reviews = json.reviews.filter(review => review.rating != 0);
          }

          dispatch(changeTitleAndLongDesc(json));
          dispatch(fetchingProductRequestSaga(id));
          dispatch(fetchingProductPriceInventory(id));
        })
        .catch(err => {
          console.info("payload err", err);

          dispatch(fetchingItemFailure(err));
        });
    }
  };
};

export const getSupplierInfo = id => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    fetch(
      GET_SUPPLIER_INFO.replace("$PRODUCT", id).replace("$LANGUAGE", language),
      { cache: "no-store" }
    )
      .then(res => res.json())
      .then(json => {
        dispatch({ type: SUPPLIER_INFO_SUCCESS, payload: json.__Result });
      })
      .catch(err => console.error(err));
  };
};

export const showContinueModalAfterAddingToCartAction = (quoteMode = false) => {
  return dispatch => {
    dispatch({
      type: quoteMode ? SHOW_CONTINUE_MODAL_QUOTE : SHOW_CONTINUE_MODAL
    });
  };
};

export const addToLocalMiniCart = (title, price, qty, supplier) => {
  return dispatch => {
    dispatch({
      type: SHOW_CONTINUE_MODAL,
      payload: { title, price, qty, supplier }
    });
  };
};
export const addToLocalMiniCartQute = (title, price, qty, supplier) => {
  return dispatch => {
    dispatch({
      type: SHOW_CONTINUE_MODAL_QUOTE,
      payload: { title, price, qty, supplier }
    });
  };
};

const requestDeliveryOptionsAction = payload => ({
  type: deliveryOptionsActions.REQUEST_DELIVERY_OPTIONS,
  payload: payload
});

export const getDeliveryOptions = (
  distributorId,
  invCode,
  qty,
  id,
  attributesObject,
  quoteMode = false,
  vid = null
) => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    let historyID = "";
    let form = new FormData();
    form.append("basketeditmode", false);
    form.append("suppressautochooseinstock", false);
    form.append("distributorId", distributorId);
    form.append("mode", "query");
    form.append("invcode", invCode);
    form.append("invhistid", "");
    form.append("qty", qty);
    form.append("vid", vid || VID);
    form.append("ml", language);

    /*     let tempJsonForm = {};
    form.forEach((value, key) => {
      tempJsonForm[key] = value;
    });
    let jsonForm = JSON.stringify(tempJsonForm); */

    dispatch(
      requestDeliveryOptionsAction({
        form,
        id,
        qty,
        distributorId,
        attributesObject,
        quoteMode,
        vid
      })
    );

    // fetch(GET_DELIVERY_OPTIONS, {
    //   method: "POST",
    //   body: form,
    //   headers: {
    //     Accept: "*/*",
    //     credentials: "same-origin"
    //   },
    //   mimeType: "multipart/form-data",
    //   data: form
    // })
    //   .then(res => res.json())
    //   .then(json => {
    //     //  dispatch({ type: ADD_TO_CART_SUCCESS });
    //     console.info("getDeliveryOptions", json);

    //     historyID = json.__Result.invhistid;
    //   })
    //   .then(() => {
    //     dispatch(
    //       addToCartProduct(
    //         id,
    //         qty,
    //         historyID,
    //         distributorId,
    //         attributesObject,
    //         quoteMode
    //       )
    //     );
    //   })
    //   .catch(err => console.error(err));
  };
};

export const addToCartProduct = (
  id,
  qty,
  historyId,
  distributorId,
  attributesObject,
  quoteMode,
  vid
) => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    var form = new FormData();
    form.append(
      "productoption",
      `productoption.html?vid=${vid || VID}&cid=891&qp=0`
    );
    form.append("replaceditemmsg_0", "");
    form.append("viewMode", "item");
    form.append("actionMode", "buy");
    form.append("selfUri", "storeitem.html");
    form.append("targetUri", "basket.html");
    form.append("mode", `${quoteMode ? "addQuote" : "addItem"}`);
    form.append("itemscount", "1");
    form.append("_targetaddItem", `basket.html?vid=${vid || VID}`);
    form.append("_targetaddQuote", `basket.html?vid=${vid || VID}`);
    form.append("basketItems[0].itemId", id);
    form.append("basketItems[0].vendorId", vid || VID);
    form.append("basketItems[0].itemToProcess", true);
    form.append("basketItems[0].quantity", qty);
    form.append("basketItems[0].inventoryHistoryId", historyId);
    form.append("basketItems[0].distributorId", distributorId);

    if (attributesObject != null) {
      let keys = Object.keys(attributesObject);
      let attributeIdandOptionIds = [];
      console.info("attr -keys", keys);

      keys.forEach((key, keyIndex) => {
        console.info("attr -key", key);

        let attribute = attributesObject[key];
        console.info("Form", attribute);
        // Get only the attributeid and the optionid objects
        let attributeKeys = ["attributeid"];

        if (attribute && attribute.optionid) {
          attributeKeys.push("optionid");
        } else {
          attributeKeys.push("value");
        }

        attributeIdandOptionIds.push([
          attribute[attributeKeys[0]],
          attribute[attributeKeys[1]]
        ]);

        attributeKeys.forEach(key => {
          form.append(
            `basketItems[0].attributes[${keyIndex}].${key.replace("id", "Id")}`,
            attribute[key]
          );
        });
      });

      // append the last bit of attributeid and optonid part.
      if (attributeIdandOptionIds.length > 0) {
        for (let attributeAndOptionId of attributeIdandOptionIds) {
          if (
            attributesObject &&
            attributesObject[attributeAndOptionId[0]] &&
            attributesObject[attributeAndOptionId[0]].optionid
          ) {
            form.append(
              `attribute_${attributeAndOptionId[0]}`,
              attributeAndOptionId[1]
            );
          } else {
            let dataname = attributesObject[attributeAndOptionId[0]].dataname;
            form.append(`${dataname}`, attributeAndOptionId[1]);
          }
        }
      }
    }

    //form.append("redirectMode", true);

    for (let data of form.values()) {
      console.info("Form", data);
    }

    fetch(
      vid
        ? ADD_TO_SUPP_CART(vid, id, language)
        : ADD_TO_CART.replace("$PRODUCT", id).replace("$LANGUAGE", language),
      {
        method: "POST",
        body: form,
        headers: {
          Accept: "*/*",
          credentials: "same-origin"
        },
        mimeType: "multipart/form-data",
        data: form
      }
    )
      .then(res => {
        res.text();
      })
      .then(text => {
        console.info("add to cart result", text);
        dispatch(fetchingProductPriceInventory(id));
        dispatch(showContinueModalAfterAddingToCartAction(quoteMode));

        dispatch({
          type: addToCartActions.SUCCESS_ADD_TO_CART,
          payload: quoteMode ? "addQuote" : "addItem"
        });
      })
      .then(() => {
        dispatch({ type: REQUEST_BASKET_AFTER_ADDING_TO_CART });
      })
      .catch(err => console.error(err, err.message));
  };
};

export const changeProductAttributesAction = payload => ({
  type: CHANGE_PRODUCT_ATTRIBUTES,
  payload: payload
});

export const populateAccessoryModal = payload => ({
  type: POPULATE_ACCESSORY_MODAL,
  payload: payload
});

export const closeAccessoryModal = () => ({
  type: CLOSE_ACCESSORY_MODAL
});

export const fetchingModalProductRequestSaga = id => ({
  type: GET_MODAL_ITEM_FETCH_REQUEST,
  payload: { id }
});

export function* fetchModalProductAction(action) {
  let language = store.getState().mainReducer.lang;
  try {
    let link = GET_ITEM_LINK;

    link = link
      .replace("$ITEMREPLACE", action.payload.id)
      .replace("$LANGUAGE", language);

    const product = yield call(api, link);

    yield put(fetchingModalItemSuccess(product.json, action.payload.id));
  } catch (e) {
    console.error("MODAL FAILED", e);
  }
}

export const fetchingModalItemSuccess = (json, id) => ({
  type: GET_MODAL_ITEM_FETCH_SUCCESS,
  payload: { json, id }
});

export const fetchingAccessoryPriceInventory = id => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    fetch(
      GET_PRICE_INVENTORY.replace("$PRODUCT", id).replace("$LANGUAGE", language)
    )
      .then(res => res.json())
      .then(json =>
        dispatch({
          type: UPDATE_ACCESSORY_INVENTORY,
          payload: json.__Result[0]
        })
      );
  };
};

export const fetchingCheckboxItemPriceInventory = (item, mode) => {
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    fetch(
      GET_PRICE_INVENTORY.replace("$PRODUCT", item.id).replace("$LANGUAGE", language)
    )
      .then(res => res.json())
      .then(json => {
        let i = item;
        i.distId = json.__Result[0].prices[0].distributorId;
        i.code = json.__Result[0].code
        dispatch({
          type: SET_SELECTED_CHECKBOX_ITEMS,
          payload: {item, mode}
        });
      });
  };
};
