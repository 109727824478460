import {
  FETCH_WISHLIST_SUCCESS,
  TOGGLE_WISHLIST_SUCCESS,
  WISHLIST_ADD_REDUX
} from "../types.js";
import {
  WISHLIST_LINK,
  WISHLIST_ADD_LINK,
  WISHLIST_REMOVE_LINK
} from "../links.js";

export const addFunctionWishList = wishlist => ({
  type: WISHLIST_ADD_REDUX,
  payload: wishlist
});

export const fetchWishListAction = wishlist => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: { wishlist: wishlist }
});

export const toggleWishListAction = (
  id,
  code,
  title,
  desc,
  currency_sign,
  image,
  price,
  url,
  wishListState
) => {
  return dispatch => {
    id = String(id);

    if (!wishListState.some(w => w.id == id)) {
      dispatch(
        addFunctionWishList([
          ...wishListState,
          { id, code, title, desc, currency_sign, image, price, url }
        ])
      );
    } else {
      let tempWishList = wishListState.filter(w => w.id != id);
      dispatch(addFunctionWishList([...tempWishList]));
    }
  };
};
