import React, { useContext } from "react";
import "./styles/CookiePage.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import FTbtn from "./FTbtn";
import icon from "../../assets/img/link-icon.png";
import { I18nContext } from "../../i18n";

function CookiePage(props) {
  const { translate } = useContext(I18nContext);
  const [onState, setOnState] = useState(true);

  const toggleHandler = () => {
    setOnState(!onState);
  };

  const saveLocalStorageHandler = () => {
    if (onState) {
      let consent = {
        date: new Date(),
        consent: "Manage-Cookie: ON",
        isAccept: true
      };
      localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
    } else {
      let consent = {
        date: new Date(),
        consent: "Manage-Cookie: OFF",
        isAccept: false
      };
      localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
    }
  };

  return (
    <div className="cookiePage">
      <h1>{translate("cookiePage.title")}</h1>
      <div className="first-para">
        <p>{translate("cookiePage.paragraph1")}</p>
        <br />
        <p style={{ fontWeight: "bold" }}>
          {translate("cookiePage.paragraph2")}
        </p>
      </div>

      <hr />

      <h2>{translate("cookiePage.header")}</h2>

      <div style={{ display: "block" }}>
        <fieldset className="step1-field">
          <div className="step1-div1">
            <span>
              <span className="step1-span1">
                {translate("cookiePage.paragraph3")}
              </span>
              <div className="step1-div1-div1">
                <strong>{translate("cookiePage.paragraph4")}</strong>
                &nbsp;{translate("cookiePage.paragraph5")}
              </div>
            </span>

            <span style={{ width: "60%" }}>
              <FTbtn onState={onState} toggle={toggleHandler} />
            </span>
          </div>
        </fieldset>
      </div>

      <p>{translate("cookiePage.paragraph6")}</p>

      <div className="step1-acceptBtn">
        <Link to="/">
          <button className="fixedBtn" onClick={saveLocalStorageHandler}>
            {translate("cookiePage.acceptButtonText")}
          </button>
        </Link>
      </div>

      <hr />

      <h2>{translate("cookiePage.header2")}</h2>
      <div className="step2-first-para">
        <p>{translate("cookiePage.paragraph7")}</p>
      </div>
      <p>
        <strong>{translate("cookiePage.paragraph8")}</strong>
      </p>
      <p>{translate("cookiePage.paragraph9")}</p>

      <table className="step2-table">
        <tbody>
          <tr>
            <th
              className="table__cell-heading"
              scope="row"
              style={{ width: "180px" }}
            >
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
              >
                <u style={{ color: "#0D7680" }}>Google Doubleclick</u>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                ></img>
              </a>

              <a
                href="https://support.google.com/ads/answer/2662922?hl=en-GB"
                target="_blank"
              >
                <u style={{ color: "#0D7680" }}>
                  {translate("cookiePage.manageCookies")}
                </u>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                ></img>
              </a>
            </th>
            <td className="table__cell-content">
              {translate("cookiePage.googlePartner")}
            </td>
          </tr>
          <tr>
            <th className="table__cell-heading" scope="row">
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
              >
                <u style={{ color: "#0D7680" }}>Google</u>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                ></img>
              </a>
              <br />
              <a
                href="https://support.google.com/ads/answer/2662922?hl=en-GB"
                target="_blank"
              >
                <u style={{ color: "#0D7680" }}>
                  {translate("cookiePage.manageCookies")}
                </u>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                ></img>
              </a>
            </th>
            <td className="table__cell-content">
              {translate("cookiePage.googleAds")}
            </td>
          </tr>

          <tr>
            <th className="table__cell-heading" scope="row"></th>
            <td className="table__cell-content"></td>
          </tr>
        </tbody>
      </table>

      <br />

      <hr />

      <h2>{translate("cookiePage.header3")}</h2>

      <p>{translate("cookiePage.paragraph10")}</p>
      <br />
      <ul className="step3-third-party-list">
        <li>
          <a
            href="https://policies.google.com/technologies/cookies?hl=en"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>Google</u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>{" "}
          -{" "}
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>
              {translate("cookiePage.manageCookiesGoogle")}
            </u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>
        </li>
        <li>
          <a
            href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>Twitter</u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>{" "}
          -{" "}
          <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank">
            <u style={{ color: "#0D7680" }}>
              {translate("cookiePage.manageCookiesAdChoices")}
            </u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/policy/cookies/" target="_blank">
            <u style={{ color: "#0D7680" }}>Facebook</u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>{" "}
          -{" "}
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>
              {translate("cookiePage.manageCookiesAdChoices")}
            </u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/legal/cookie-policy"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>LinkedIn</u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>{" "}
          -{" "}
          <a
            href="https://www.linkedin.com/legal/l/cookie-table"
            target="_blank"
          >
            <u style={{ color: "#0D7680" }}>
              {translate("cookiePage.manageCookiesLinkedIn")}
            </u>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }}></img>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default CookiePage;
