import React from "react";
import history from "../../history.js";
import Async from "react-code-splitting";
import InnerHTML from "dangerously-set-inner-html";
import * as Content from "./Content.js";
import { staticContents } from "./StaticRoutes.js";
import $ from "jquery";

import { Helmet } from "react-helmet";

const Footer = () => <Async load={import("../AC-Footer/Footer.jsx")} />;

function StaticContent(props) {
   let link = history.location.pathname.split("/");
  if (link[link.length - 1] == "") {
    link = link[link.length - 2];
  } else {
    link = link[link.length - 1];
  }

  let indexLocation;

  let content = staticContents.filter( (fil,index) => {
    if (fil[1] == link) {
      indexLocation = index;
      return true;
    } else {
      return false;
    }
  })[0][0];

  return (
    <div>
      <Helmet>
        <title>{staticContents[indexLocation][2]}</title>
        <meta name="description" content={staticContents[indexLocation][2]} />
      </Helmet>
      <InnerHTML html={Content[content]} />
      <Footer />
    </div>
  );
}

export default StaticContent;
