import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import PulseMarker from "../AC-UI-Elements/PulseMarker";
import MarkerIcon from "../../assets/icons/marker.png";
import "./Styles/ReactMap.css";
import { classes } from "istanbul-lib-coverage";
import { I18nContext } from "../../i18n/index";

function getAccessToken() {
  var accessToken = null;

  if (typeof window !== "undefined" && window.location) {
    var match = window.location.search.match(/access_token=([^&\/]*)/);
    accessToken = match && match[1];
  }

  if (!accessToken && typeof process !== "undefined") {
    // Note: This depends on bundler plugins (e.g. webpack) inmporting environment correctly
    accessToken = accessToken || process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN; // eslint-disable-line
  }

  return accessToken || null;
}

// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens
const MAPBOX_TOKEN = getAccessToken();

const Map = props => {
  const dispatch = useDispatch();
  const { langCode } = useContext(I18nContext);

  const mapRef = useRef();
  const geocoderContainerRef = useRef();

  const userLocation = useSelector(
    state => state.userLocationReducer,
    shallowEqual
  );

  const storesState = useSelector(
    state => state.categoryReducer.categoryItems,
    shallowEqual
  );

  const [viewport, setViewport] = useState({
    latitude: userLocation.lat ? userLocation.lat : 38,
    longitude: userLocation.lng ? userLocation.lng : 35,
    zoom: userLocation.lat ? 9 : 1
  });

  const [selectedStore, setSelectedStore] = useState(null);

  const setUserLoc = () => {
    navigator.geolocation.getCurrentPosition(position => {
      let userLocationTemp = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      const newViewport = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        zoom: 9
      };

      setViewport(newViewport);
      //  dispatch(setUserLocation(userLocationTemp));
      // setUserLocation(userLocation);
    });
  };

  const handleViewportChange = viewPort => {
    setViewport(viewPort);
  };

  const handleGeocoderViewportChange = viewPort => {
    handleViewportChange(viewPort);
  };

  const handleOnResult = event => {
    let userLocationTemp = {
      latitude: event.result.geometry.coordinates[1],
      longitude: event.result.geometry.coordinates[0]
    };
    let newViewport = {
      latitude: event.result.geometry.coordinates[1],
      longitude: event.result.geometry.coordinates[0],
      zoom: 12
    };
    //    dispatch(updateUserAddress(event.result.place_name));
    setViewport(newViewport);

    //    dispatch(setUserLocation(userLocationTemp));
  };

  if (userLocation && userLocation.lat && storesState) {
    return (
      <>
        <MapGL
          ref={mapRef}
          {...viewport}
          mapStyle="mapbox://styles/avetti-lucass/ck58hdw5s0xfe1coxltpx8cwy"
          width="100%"
          height="400px"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <div className="getLocationButton">
            <button onClick={setUserLoc}>Get Location</button>
          </div>
          <Geocoder
            className="TEST"
            mapRef={mapRef}
            onResult={handleOnResult}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          />

          <div style={{ position: "absolute", right: "10px", bottom: "40px" }}>
            <NavigationControl />
          </div>

          {storesState
            .filter(store => store.properties.lat && store.properties.lng)
            .map((store, index) => (
              <Marker
                key={index}
                offsetLeft={-15}
                offsetTop={-30}
                latitude={parseFloat(store.properties.lat)}
                longitude={parseFloat(store.properties.lng)}
              >
                <button
                  className="markerBtn"
                  onClick={e => {
                    e.preventDefault();
                    console.log(store);
                    setSelectedStore(store);
                  }}
                >
                  <img width="30px" height="30px" src={MarkerIcon}></img>
                </button>
              </Marker>
            ))}

          {selectedStore ? (
            <Popup
              latitude={parseFloat(selectedStore.properties.lat)}
              longitude={parseFloat(selectedStore.properties.lng)}
              offsetTop={-35}
              offsetLeft={5}
              onClose={() => {
                setSelectedStore(null);
              }}
            >
              <div style={{ display: "flex", padding: "5px", zIndex: "1000" }}>
                <img width="125px" src={selectedStore.image}></img>
                <div>
                  <p style={{ fontWeight: "bold" }}>{selectedStore.title}</p>
                </div>
              </div>
            </Popup>
          ) : null}

          {Object.keys(userLocation).length !== 0 ? (
            <Marker
              offsetLeft={-25}
              offsetTop={-25}
              latitude={userLocation.lat}
              longitude={userLocation.lng}
            >
              <PulseMarker />
            </Marker>
          ) : null}
        </MapGL>
      </>
    );
  } else {
    return (
      <div className="map-not-rendered">
        <p>
          Could not retrieve the user location automatically, please try again
          after disabling the adblocker if there is any. We are not displaying
          any ads on this site.
        </p>
      </div>
    );
  }
};

export default Map;
