import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { PREVIEW } from "../../project-config.js";
import history from "../../history.js";
import Category from "../../pages/Category.jsx";

import {
  fetchCategoryFromRender,
  changeKeyword,
  changeCategoryName
} from "../../redux/actions/categoryActions.js";

import { I18nContext } from "../../i18n";

export default function Search() {
  const { translate, langCode } = useContext(I18nContext);
  const dispatch = useDispatch();

  const navCategoryState = useSelector(
    state => state.menuReducer.navCategory,
    shallowEqual
  );

  console.info("langCode", langCode);

  let keyword = history.location.pathname;
  if (window.location.pathname.includes("search/"))
    keyword = history.location.pathname.split(`search/`)[1];

  useEffect(() => {
    dispatch(changeKeyword(keyword));
    dispatch(changeCategoryName(keyword, [], "search", ""));
    dispatch(
      fetchCategoryFromRender("search", keyword, [], keyword, navCategoryState)
    );
  }, [keyword]);

  return <Category />;
}
