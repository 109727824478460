import React, { useEffect } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import MenuCore from "../header/MenuCore.jsx";

import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Messaging from "../components/AC-Messaging/Messaging.jsx";
import { fetchDirectUrlGetItem } from "../redux/actions/productAction";

import { I18nContext, mapCountryList } from "../i18n/index";
import { useParams } from "react-router";

function Menu(props) {
  console.info("borop params", props.match);

  const { langCode } = React.useContext(I18nContext);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    state => state.menuReducer.isLoading,
    shallowEqual
  );

  const [completed, setCompleted] = React.useState(0);

  const ColorLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "#ff0"
    },
    barColorPrimary: {
      backgroundColor: "#ff0"
    }
  })(LinearProgress);
  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setTimeout(progress, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleWhatToRenderHere = () => {
    if (props.component === "messages") return <Messaging />;
    else return <MenuCore component={props.component} />;
  };

  useEffect(() => {
    let count = 0;
    if (props.component == "product" && count == 0) {
      count++;
      console.info("new url KACKERE GIRDIN", count);
      let languageStorage = localStorage.getItem("language");
      if (!languageStorage) {
        localStorage.setItem("language", langCode);
        languageStorage = "en";
      }
      let countryStorage = localStorage.getItem("country");
      if (!countryStorage) {
        countryStorage = "en";
        localStorage.setItem("country", countryStorage);
      }
      console.info(
        "new url4---",
        window.location.pathname,
        langCode,
        languageStorage,
        countryStorage,
        props.component
      );

      /*  const windowPathNameDoesNotIncludeOtherCountryCodesButCanHaveEn = () => {
        if (langCode == "en") {
          if (
            window.location.pathname.includes(`/en/`) ||
            window.location.pathname.includes(`/ca/`)
          ) {
            return true;
          }

          return !mapCountryList.some(code =>
            window.location.pathname.includes(`/${code}/`)
          );
        } else {
          return false;
        }
      };

      if (
        window.location.pathname.includes(`/${langCode}/`) ||
        windowPathNameDoesNotIncludeOtherCountryCodesButCanHaveEn()
      ) { */
      console.info(
        "new url4--- CONTAINS",
        window.location.pathname,
        localStorage.getItem("language"),
        window.location.pathname.includes(
          `/${localStorage.getItem("language")}/`
        )
      );
      if (
        window.location.pathname.includes(
          `/${localStorage.getItem("language")}/`
        ) === false
      ) {
        console.info("new url4--- GIRDI", window.location.pathname);

        dispatch(
          fetchDirectUrlGetItem(
            window.location.pathname,
            localStorage.getItem("language"),
            countryStorage
          )
        );
      } else {
        console.info("new url4--- GIRDI2", window.location.pathname);

        dispatch(
          fetchDirectUrlGetItem(
            window.location.pathname,
            langCode,
            countryStorage
          )
        );
      }
      /*   } */
    }
    return () => {
      count = 0;
    };
  }, [props.component, window.location.pathname, langCode]);

  if (isLoading) {
    return (
      <div>
        <LinearProgress
          color="primary"
          variant="indeterminate"
          value={completed}
          className="LinearPro"
        />
      </div>
    );
  } else {
    return <MenuCore component={props.component} />;
  }
}

export default Menu;
