import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./FeaturedPromotions.css";
import { PREVIEW } from "../project-config";

import Image1 from "../assets/img/slider/1.jpg";
import Image2 from "../assets/img/slider/2.jpg";
import Image3 from "../assets/img/slider/3.jpg";
import Image4 from "../assets/img/slider/4.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { I18nContext } from "../i18n/index";
import {
  fetchCategoryFromRender,
  changeCategoryName
} from "../redux/actions/categoryActions.js";
import { FormattedNumber } from "react-intl";
const FeaturedPromotions = () => {
  const dispatch = useDispatch();
  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const { translate, priceConvert, currency } = React.useContext(I18nContext);

  const lang = useSelector(state => state.mainReducer.lang, shallowEqual);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1600, min: 1360 },
      items: 1
    },
    mdDesktop: {
      breakpoint: { max: 1360, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 520 },
      items: 1
    },
    xsMobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1
    }
  };
  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    let catName = cat;

    if (cat.includes("&amp;")) {
      catName = cat.replace("&amp;", "&");
    }
    const category = navCatsState.childs.filter(c => {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    // dispatch(changeCategoryName(catName, parents, longdesc));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    }
  };

  return (
    <div style={{
      marginTop: "4%"
    }}>



<div className="newsletter-wrapper">
      <div className="newsletter-content">
        <h3>Take Your Business <br/>


Online and Start <br/>


Expanding. It's free!
              </h3>
              <span className="cafe-button">
                <a href={PREVIEW + "/register.html?vid=20200603781&mt=1&ml=en"} target="_blank">Register Now</a>
                </span>
      </div>

      <div className="mailchimp-wrapper">
        
      <Carousel
  additionalTransfrom={0}
  arrows={false}
  autoPlay
  autoPlaySpeed={4000}
  centerMode={false}
  className=""
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
  <img
    src= {Image2}
    style={{
      display: 'block',
      height: '100%',
      margin: 'auto',
      width: '100%'
    }}
  />
  <img
    src= {Image1}
    style={{
      display: 'block',
      height: '100%',
      margin: 'auto',
      width: '100%'
    }}
  />
  <img
    src= {Image3}
    style={{
      display: 'block',
      height: '100%',
      margin: 'auto',
      width: '100%'
    }}
  />
  <img
    src= {Image4}
    style={{
      display: 'block',
      height: '100%',
      margin: 'auto',
      width: '100%'
    }}
  />
  </Carousel>

      </div>
    </div>
      
          {/* <Link
            to={
              lang !== "en"
                ? PREVIEW + `/${lang}` + "/shop/laptops"
                : PREVIEW + "/shop/laptops"
            }
            onClick={() =>
              handleCategoryChange(
                "432307",
                "Laptops",
                ["Laptops", "432307"],
                "Laptops"
              )
            }
          ></Link>
            
            
              <h3 className="cafe-banner-title">
              >
              
                
                
              
          </Link> */}
        

        

     
      

      
    </div>
  );
};

export default FeaturedPromotions;
