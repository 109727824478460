import React, { useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getDeliveryOptions,
  addToLocalMiniCart,
  setAddToCartValidationErrors,
  setProductOutOfStockError
} from "../../../../redux/actions/productAction";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";
import "./Styles/AddToCartBox.css";
import NotificationRequest from "../../Components/NotificationRequest/NotificationRequest";
import Loading from "../../../AC-Loading/Loading";

const WIGGLE_TIMER = 2500;

const AddToCartBox = ({ handleEnquiryModalOpenClicked }) => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = React.useContext(I18nContext);

  const [firstDistId, setFirstDistId] = React.useState(0);
  const [price, setPrice] = React.useState(null);
  const [numberOfItems, setNumberOfItems] = React.useState(1);
  const [shouldWiggle, setShouldWiggle] = React.useState(false);
  const [perUnitState, setPerUnitState] = React.useState("");
  const [inStock, setInStock] = React.useState(true);
  const initialValue = useRef(true);

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  console.info("borop priceConvert", priceConvert, currency);

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    }
  }, [isProductDetailsLoading]);

  useEffect(() => {
    if (
      Object.keys(priceState).length > 0 &&
      supplierInfoState &&
      supplierInfoState[0] &&
      supplierInfoState[0].distributorOrder
    ) {
      let firstDistId = supplierInfoState[0].distributorOrder[0].distid;
      setFirstDistId(firstDistId);
      setPrice(
        priceState.prices.find(inv => inv.distributorId == firstDistId).price_1
      );
      // determine stock status
      setInStock(
        priceState.invs[0].instock > 0 ||
          (mainItemIdState === 0 && skusState.length > 0)
      );
    }
  }, [priceState, supplierInfoState]);

  useEffect(() => {
    let timer = null;
    if (numberOfItems) {
      if (initialValue.current) {
        initialValue.current = false;
        return;
      }

      timer = setTimeout(() => {
        setShouldWiggle(true);
      }, WIGGLE_TIMER);
    }
    return () => {
      setShouldWiggle(false);
      clearTimeout(timer);
    };
  }, [numberOfItems]);

  useEffect(() => {
    if (productInitialProperties && productInitialProperties.length > 0) {
      let perUnitProp = productInitialProperties.find(prop =>
        ["Per Unit", "Per-Unit"].includes(Object.keys(prop)[0])
      );
      console.info("perUnitProp", productInitialProperties, perUnitProp);
      if (perUnitProp) setPerUnitState(Object.values(perUnitProp)[0]);
    }
  }, [productInitialProperties]);

  console.info("shouldWiggle", shouldWiggle);

  const handleAddToCart = (quoteMode = false) => {
    if (!inStock) {
      dispatch(setProductOutOfStockError(true));
      return;
    }
    let attributesObject = null;

    let requiredFields = [];

    if (
      selectedProductAttributesState.hasOwnProperty(
        mainItemIdState || itemIdState
      )
    ) {
      attributesObject =
        selectedProductAttributesState[mainItemIdState || itemIdState];

      let expectedAttributes = attributeDetailsState.reduce((p, c) => {
        const { screenname, attributeid } = c;
        p = [...p, { screenname, attributeid }];
        return p;
      }, []);

      let receivedAttributeIds = Object.keys(attributesObject);

      console.info(
        "addToCartTest",
        attributesObject,
        expectedAttributes,
        receivedAttributeIds
      );

      if (mainItemIdState != 0) {
        expectedAttributes.forEach(attr => {
          if (!receivedAttributeIds.includes(attr.attributeid.toString()))
            requiredFields.push(attr.screenname);
        });
      } else {
        expectedAttributes.forEach(attr => {
          requiredFields.push(attr.screenname);
        });
      }
    } else {
      if (attributeDetailsState && attributeDetailsState.length > 0) {
        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid } = c;
          p = [...p, { screenname, attributeid }];
          return p;
        }, []);

        expectedAttributes.forEach(attr => {
          requiredFields.push(attr.screenname);
        });
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));

    if (requiredFields.length > 0) {
      return;
    }

    let firstDist = supplierInfoState[0].distributorOrder[0];
    let vid = firstDist.supplier_store_vid;
    console.info("firstDist", firstDist, vid);

    dispatch(
      getDeliveryOptions(
        priceState.prices.find(inv => inv.distributorId == firstDistId)
          .distributorId,
        priceState.code,
        numberOfItems,
        priceState.itemid,
        attributesObject,
        quoteMode,
        vid
      )
    );
  };

  const handleSetQuantityInput = e => {
    const value = Number(e.target.value);
    if (value > 0) setNumberOfItems(Number(e.target.value));
  };

  const renderPerUnitText = () => {
    if (perUnitState) {
      return (
        <small className="add-to-cart-box--per-unit-text">{perUnitState}</small>
      );
    } else return null;
  };

  const renderStockStatus = () => {
    console.info("borop stock", inStock);
    if (inStock) {
      return null;
    } else {
      return <div className="add-to-cart-stock-status">Out of Stock</div>;
    }
  };

  return (
    <React.Fragment>
      {requestingAddToCartState ? (
        <Loading className="addToCartLoading" />
      ) : null}
      <div id="addToCardGrid">
        <div className="buyBox">
          <div className="buy-box-qty-price-container">
            <div className="buy-box-qty-wrapper">
              <div id="buyBoxQtyBox">
                <div className="qtyControlsBox">
                  <div className="qtyControlsBtns">
                    <div
                      className="qtyControlsPlus no-select"
                      onClick={() => setNumberOfItems(numberOfItems + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>+</span>
                    </div>
                    <div
                      className="qtyControlsMinus no-select"
                      onClick={() => {
                        if (numberOfItems - 1 > 0) {
                          setNumberOfItems(numberOfItems - 1);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>-</span>
                    </div>
                  </div>
                  <input
                    size={String(numberOfItems).length}
                    className="qtyControlsInput"
                    type="text"
                    value={numberOfItems}
                    onChange={e => {
                      handleSetQuantityInput(e);
                    }}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div
              /*   style={{
                visibility: isProductDetailsLoading ? "hidden" : "visible"
              }} */
              className="buy-box-price-wrapper"
            >
              <div id="buyBoxPrice">
                {isProductDetailsLoading ? (
                  <Loading className="add-to-cart-box--price-loading" />
                ) : (
                  <FormattedNumber
                    value={price / priceConvert}
                    style="currency"
                    currency={currency}
                  />
                )}

                {/*<span className="symbol">
                {currencyState.sign && currencyState.sign}
              </span>
              <span className="dollars">{price}</span>
                  {currencyState.code && currencyState.code}*/}
              </div>
              <NotificationRequest />
            </div>
            {renderPerUnitText()}
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div id="buyBoxItemInfo2"></div>
              <div id="buyBoxItemInfo3"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              {renderStockStatus()}
              <div
                id="buyBoxAddToCartBtn"
                className={inStock ? "" : "out-of-stock"}
              >
                <div
                  className={`addToCartBtn${shouldWiggle ? " wiggle" : ""}`}
                  title={translate("js.item.addtocart")}
                  onClick={() => handleAddToCart()}
                >
                  <div>
                    <span>{translate("js.item.addtocart")} </span>
                  </div>
                  <div>
                    <i className="material-icons add-icon">add_shopping_cart</i>
                  </div>
                </div>
              </div>
              {/* <div id="buyBoxQuoteBtn">
                <div
                  className="addToCartBtn"
                  title={translate("js.item.addtoquote")}
                  onClick={() => handleAddToCart(true)}
                >
                  <div>
                    <span>{translate("js.item.addtoquote")}</span>
                  </div>
                  <div>
                    <i className="material-icons add-icon">description</i>
                  </div>
                </div>
              </div> */}
              {/* <div id="buyBoxEnquiryBtn">
                <div
                  id="enquiry-204"
                  className="addToCartBtn sendEnquiryBtn"
                  title={translate("js.item.enquiry")}
                  onClick={handleEnquiryModalOpenClicked}
                >
                  <div>{translate("js.item.enquiry")}</div>
                  <div>
                    <span>
                      <i className="material-icons add-icon">mail_outline</i>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddToCartBox;
