import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PREVIEW } from "../../../../project-config";
import MoreShare from "./components/MoreShare.jsx";
import htmldecoder from "../../../../functions/htmldecoder";
import { runAfterSomeTime } from "../../../../functions/Utilities.js";
import { toggleCompare } from "../../../../header/Compare.jsx";
import {
  deleteCompareItem,
  toggleCompareAction,
  fetchComparedItemDetails,
  deleteComparedItemsDetails
} from "../../../../redux/actions/compareActions";

import history from "../../../../history.js";
import { FormattedNumber } from "react-intl";
import { I18nContext, langCodeList } from "../../../../i18n/index";
import "./Styles/ItemCard.css";
import PriceTag from "../../../../shared/components/PriceTag/PriceTag";
import { unMountProductPageAction } from "../../../../redux/actions/productAction";

const MORE_SHARE_TOGGLE_TIMEOUT = 100;

const ItemCard = props => {
  const { recently = false } = props;
  const dispatch = useDispatch();
  const { langCode, currency, translate, priceConvert } = React.useContext(
    I18nContext
  );

  const [productOnSale, setProductOnSale] = useState(
    false /* Math.round(Math.random()) */
  );

  const [moreActive, setMoreActive] = useState(false);

  const {
    id,
    code,
    title,
    desc,
    currency_sign,
    image,
    itemLargeImage,
    price,
    url,
    properties
  } = props.itemCard;

  const [productPromotion, setProductPromotion] = useState(
    properties && properties.PromoText
  );

  console.info("item card props", props, productPromotion);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [favouriteState, setFavouriteState] = useState("favorite_border");
  const [compareIconState, setCompareIconState] = useState("");

  const compareListState = useSelector(
    state => state.compareListReducer.compareList,
    shallowEqual
  );

  let imageUrl = image;

  if (imageUrl.includes("sls3.avetti.ca")) {
    imageUrl = imageUrl.replace(
      "sls3.avetti.ca",
      "demob2b2cs3.avetti.ca/store"
    );
  }

  let isItemWishlisted = props.wishListState.some(w => w.id == id);
  let isItemCompared =
    compareListState && compareListState.some(i => i.id == id);

  const handleToggleWishlistIcon = (e, id) => {
    e.stopPropagation();
    isItemWishlisted
      ? setFavouriteState("favourite_border")
      : setFavouriteState("favourite");
    props.toggleWish(
      e,
      id,
      code,
      title,
      desc,
      currency_sign,
      image,
      price,
      url
    );
    runAfterSomeTime(() => setMoreActive(false), MORE_SHARE_TOGGLE_TIMEOUT);
  };

  const handleToggleCompareListIcon = (event, itemId) => {
    event.stopPropagation();
    let compareid = String(itemId);
    isItemCompared && compareIconState === ""
      ? setCompareIconState("-outlined")
      : setCompareIconState("");

    // if item's compare checkbox is not checked
    if (!isItemCompared) {
      dispatch(fetchComparedItemDetails(compareid));
    } else {
      dispatch(deleteComparedItemsDetails(compareid));
    }

    toggleCompare(
      //event,
      compareid,
      title,
      currency_sign,
      image,
      price,
      url,
      compareListState,
      isItemCompared,
      dispatch,
      deleteCompareItem,
      toggleCompareAction,
      translate
    );

    runAfterSomeTime(() => setMoreActive(false), MORE_SHARE_TOGGLE_TIMEOUT);
  };

  const renderWishlistIcon = () => {
    return (
      <i
        className={
          isItemWishlisted
            ? "no-select material-icons item-card-wishlist-icon active"
            : "no-select material-icons item-card-wishlist-icon"
        }
        onMouseEnter={() => setFavouriteState("favorite")}
        onMouseLeave={() => setFavouriteState("favorite_border")}
        onClick={e => {
          handleToggleWishlistIcon(e, id);
        }}
      >
        {isItemWishlisted || favouriteState === "favorite"
          ? `favorite`
          : `favorite_border`}
      </i>
    );
  };

  const handleClickOnProductCard = (event, url) => {
    dispatch(unMountProductPageAction());
    console.info(`URL:${url}`, langCodeList);

    langCodeList.map(lang => {
      if (url.substring(0, 5).includes(`${lang}/`)) {
        url = url.replace(`${lang}`, "");
      }
    });

    if (url.includes("/product/")) {
      url = url.replace("/product/", "");
    } else {
      url = url.replace("product/", "");
    }

    const shouldIncludeSlash = () => {
      if (url.charAt(0) == "/") {
        return "";
      } else {
        return "/";
      }
    };

    history.push(
      langCode !== "en"
        ? PREVIEW + "/" + langCode + shouldIncludeSlash() + url
        : PREVIEW + shouldIncludeSlash(url) + url
    );
  };

  const renderCompareIcon = () => {
    return (
      <React.Fragment>
        <i
          onMouseEnter={() => setCompareIconState("-outlined")}
          onMouseLeave={() => setCompareIconState("")}
          onClick={event => handleToggleCompareListIcon(event, id)}
          className={
            isItemCompared
              ? `no-select material-icons${compareIconState} compare-icon active`
              : `no-select material-icons${compareIconState} compare-icon`
          }
        >
          {isItemCompared || compareIconState === "-outlined"
            ? `check_box`
            : `check_box_outline_blank`}
        </i>
        <span
          onClick={event => handleToggleCompareListIcon(event, id)}
          className="compare-icon-tooltip"
        >
          {translate("itemCard.compareTooltip")}
        </span>
      </React.Fragment>
    );
  };

  const renderPromotion = () => {
    if (productPromotion)
      return (
        <div className="item-card-promotion-container">
          <span className="item-card-promotion--promo">{productPromotion}</span>{" "}
        </div>
      );
    else return null;
  };

  const renderSale = () => {
    if (productOnSale)
      return (
        <div className="item-card-sale-container">
          <span className="item-card-sale--sale">50% OFF</span>{" "}
        </div>
      );
    else return null;
  };

  return (
    <div
      className={`home-item--wrapper${isMobileState ? ` mobile` : ``}${
        moreActive ? ` more-active` : ``
      }`}
      onClick={event => {
        window.scrollTo(0, 0);
        handleClickOnProductCard(event, url);
      }}
    >
      {!recently ? (
        <div className={`itemCard-buttons-container`}>
          <MoreShare
            id={id}
            moreActive={moreActive}
            setMoreActive={setMoreActive}
            compareClicked={handleToggleCompareListIcon}
            wishlistClicked={handleToggleWishlistIcon}
            url={url}
            title={title}
            imageUrl={imageUrl}
          />
        </div>
      ) : null}
      <div
        className={`home-item${isMobileState ? ` mobile` : ``}${
          moreActive ? ` more-active` : ``
        }${productPromotion ? ` promo-item` : ``}`}
        style={{ cursor: "pointer" }}
      >
        {isItemWishlisted ? renderWishlistIcon() : null}
        {renderCompareIcon()}

        <div className={`item-card-image${isMobileState ? ` mobile` : ``}`}>
          <figure className="item-card-figure">
            <span
              className="figure"
              style={{ backgroundImage: `url(${imageUrl})` }}
            ></span>
          </figure>
          {renderPromotion()}
          {renderSale()}
        </div>

        <div className={`item-card-regular${isMobileState ? ` mobile` : ``}`}>
          <div
            className="product-title"
            dangerouslySetInnerHTML={{
              __html: htmldecoder(title)
            }}
          ></div>
          <div className={`product-price${productOnSale ? " price-deal" : ""}`}>
            <div className="product-price--main">
              <PriceTag
                value={{
                  integer: price.type != "empty" ? price.value.integer : 0,
                  decimal: price.type != "empty" ? price.value.decimal : 0
                }}
              />
            </div>
            {productOnSale ? (
              <div className="product-price--deal">
                <PriceTag
                  value={{
                    integer:
                      price.type != "empty"
                        ? Math.round(price.value.integer / 2)
                        : 0,
                    decimal: price.type != "empty" ? price.value.decimal : 0
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
