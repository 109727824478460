import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";

const PROP_KEYS_TO_BE_IGNORED = [
  "Sys_Num_Images",
  "UPC",
  "Gallery Images",
  "Pdf Files",
  "Sys_Package_Price_Enabled",
  "createtime"
];

const OtherInfoTab = () => {

  const { translate } = React.useContext(I18nContext);

  const longDescState = useSelector(
    state => state.productReducer.productInitial.longdesc,
    shallowEqual
  );

  const { longdesc2, longdesc3, longdesc4 } = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const propertiesReduxState = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  const [propertiesState, setPropertiesState] = useState([]);

  useEffect(() => {

    if (propertiesReduxState && propertiesReduxState.length > 0) {
      setPropertiesState(
        propertiesReduxState.filter(
          property =>
            !PROP_KEYS_TO_BE_IGNORED.includes(Object.keys(property)[0])
        )
      );
    }

    return () => {
      setPropertiesState([]);
    };
  }, [propertiesReduxState]);

  return (
    <div
      className="product-details-specs-container"
      style={{ backgroundColor: "white" }}
    >
      <div className="product-details-specs-wrapper">
        <div className="product-details-wrapper">
          <h2>{translate("js.item.productdetails")}</h2>
          <div id="description" className="otherDesktop">
            <p
              dangerouslySetInnerHTML={{ __html: htmldecoder(longDescState) }}
            />
            {longdesc2 ? (
              <p dangerouslySetInnerHTML={{ __html: htmldecoder(longdesc2) }} />
            ) : null}
            {longdesc3 ? (
              <p dangerouslySetInnerHTML={{ __html: htmldecoder(longdesc3) }} />
            ) : null}
            {longdesc4 ? (
              <p dangerouslySetInnerHTML={{ __html: htmldecoder(longdesc4) }} />
            ) : null}
          </div>
        </div>

        <div className="product-specs-wrapper">
          <h2>{translate("js.item.specifications")}</h2>
          <div id="Specifi">
            {propertiesState && propertiesState.length > 0 ? (
              <div className="specs-list">
                <table className="table table-striped">
                  {console.info("payload err3", propertiesState)}
                  <tbody>
                    {propertiesState
                      ? propertiesState
                          .slice(0, propertiesState.length / 2)

                          .map(property => {
                            let key = Object.keys(property)[0];
                            let value = Object.values(property)[0];
                            console.info("key", key, value);
                            return (
                              <tr key={key}>
                                <th>
                                  <strong>{key}</strong>
                                </th>
                                <td>{value}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>

                <table className="table table-striped">
                  <tbody>
                    {propertiesState
                      ? propertiesState
                          .slice(
                            propertiesState.length / 2,
                            propertiesState.length
                          )
                          .map(property => {
                            let key = Object.keys(property)[0];
                            let value = Object.values(property)[0];
                            console.info("key", key, value);

                            return (
                              <tr key={key}>
                                <th>
                                  <strong>{key}</strong>
                                </th>
                                <td>{value}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>
              </div>
            ) : (
              <h3>{translate("js.item.noSpecsToShow")}</h3>
            )}
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInfoTab;
