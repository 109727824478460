import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, BrowserRouter } from "react-router-dom";
import { PROJECT_LINK, PREVIEW, VID } from "../../project-config";
import Logo from "../../assets/img/footerLogo.jpg";
import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";

import { I18nContext } from "../../i18n/index";
function Footer() {
  const { translate } = React.useContext(I18nContext);

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerContent, setFooterContent] = useState({});

  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const [activeFooterSection, setActiveFooterSection] = useState("");

  const renderLinks = arrContent => {
    return arrContent.map((content, i) => (
      <li {...content.parent.attributes} key={i}>
        {content.children.map((childContent, i) => {
          return <AnchorElement {...childContent} key={i} />;
        })}
      </li>
    ));
  };

  const finePrint = <small>Copyright &copy; 2020 Avetti.com Corporation. All Rights Reserved</small>;
  if (footerContent && Object.keys(footerContent).length > 0) {
    if (isMobileState) {
      const handleOnClickFooterSection = e => {
        const { target: clickedFooterSectionTarget } = e.target.dataset;
        setActiveFooterSection(
          activeFooterSection !== clickedFooterSectionTarget &&
            clickedFooterSectionTarget
        );

        console.info(
          "activeFooterSection",
          activeFooterSection,
          clickedFooterSectionTarget
        );
      };

      const handleWhatIconToDisplay = footerSectionName => {
        return activeFooterSection === footerSectionName
          ? `remove_circle`
          : `add_circle`;
      };
      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer_Line">
                <div>
                  <div className="footerLogo pull-left">
                    <img
                      src={Logo}
                      alt="Avetti eCommerce Logo"
                      style={{ height: "130px" }}
                    />
                  </div>
                </div>
              </div>
              {/* Mobile Version */}
              <div className="footer-container">
                <div
                  className="liHead first"
                  id="about-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="about"
                  aria-controls="about"
                  aria-expanded={activeFooterSection === "about"}
                >
                  {translate("vm.footer.learnmore")}
                  <i className="material-icons footer-icon" data-target="about">
                    {handleWhatIconToDisplay("about")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  aria-labelledby="about-menu"
                  role="region"
                  id="about"
                  style={{
                    display: activeFooterSection === "about" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.about)}</ul>
                </div>

                
                <div
                  className="liHead"
                  id="shop-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="shop"
                  aria-controls="shop"
                  aria-expanded={activeFooterSection === "shop"}
                >
                  {translate("vm.footer_contact")}
                  <i className="material-icons footer-icon" data-target="shop">
                    {handleWhatIconToDisplay("shop")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  id="shop"
                  aria-labelledby="shop-menu"
                  role="region"
                  style={{
                    display: activeFooterSection === "shop" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div>

                <div
                  className="menu-footer"
                  id="socialMedia"
                  aria-labelledby="socialMedia-menu"
                  role="region"
                  style={{
                    display: "block"
                  }}
                >
                  <ul>{renderLinks(footerContent.socialMedia)}</ul>
                </div>
              </div>
              <div id="finePrint">{finePrint}</div>
            </div>
          </div>
        </footer>
      );
    } else {
      return (
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              <div className="footer-container">
              
                <div className="liHead" id="about-menu">
                  {translate("vm.footer.learnmore")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-about-expand"
                  ></i>
                </div>
                <div className="menu-footer" id="about">
                  <ul>{renderLinks(footerContent.about)}</ul>
                </div>
                <div className="liHead" id="shop-menu">
                  {translate("vm.footer_contact")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-shop-expand"
                  ></i>
                </div>
                <div className="menu-footer" id="shop">
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div>
                <div className="liHead" id="socialMedia-menu">
                  {translate("socialMedia")}
                  <i
                    className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                    id="global--mobile-socialMedia-expand"
                  ></i>
                </div>
                
                <div className="menu-footer" id="socialMedia">
                  <div className="footerLogo pull-left">
                    <img
                      src={Logo}
                      alt="Avetti eCommerce Logo"
                      style={{ height: "120px" }}
                    />
                  </div>
                  
                  
                </div>
              </div>
              <ul>{renderLinks(footerContent.socialMedia)}</ul>
              <div id="finePrint">{finePrint}</div>
            </div>
          </div>
        </footer>
      );
    }
  } else {
    return null;
  }
}

export default Footer;
