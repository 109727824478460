import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import CookiePage from "../components/AC-Cookie/CookiePage";
import Category from "../pages/Category.jsx";
import Main from "../content/Main.jsx";
import Login from "../components/AC-LoginBox/Login.jsx";
import AboutComponent from "../components/AC-StaticPages/AboutComponent.jsx";
import Search from "../components/AC-Search/Search";
import Async from "react-code-splitting";
import MailchimpSub from "../components/AC-Modals/MailchimpSub";
import Stores from "../components/AC-Stores/Stores";
import StoreProducts from "../components/AC-Stores/components/StoreProducts";
import { handleMobileOrDesktop } from "../redux/actions/mainActions.js";
import WishList from "../components/AC-WishList/WishListItem";
import CompareItems from "../components/AC-Compare/CompareItems.jsx";
import ProductPage from "../components/AC-ProductPage/ProductPage";
import Messaging from "../components/AC-Messaging/Messaging.jsx";
import Comingsoon from "../content/ComingSoon.jsx";

import {
  categoryUnmountAction,
  categoryFetchFlagAction
} from "../redux/actions/menuActions";

import PromotionComponent from "../components/AC-Advertising/PromotionComponent";

const LocationBar = () => (
  <Async load={import("../components/AC-Location/LocationBar.jsx")} />
);
const MenuTopBar = () => <Async load={import("./MenuTopBar.jsx")} />;
const Compare = () => <Async load={import("./Compare.jsx")} />;

const StaticContent = () => <Async load={import("../components/AC-StaticPages/StaticContent.jsx")} />;

export default function MenuCore(props) {
  console.info("component2", props.component);
  const { component } = props;

  const dispatch = useDispatch();

  const [currentScreenWidth, setCurrentScreenWidth] = React.useState(
    window.innerWidth
  );
  React.useEffect(() => {
    const handleResize = () => {
      setCurrentScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (component !== "stores") {
      console.info("categoryFlagAction set true");
      dispatch(categoryFetchFlagAction(true));
    }
  }, [component]);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  const handleWhatComponentToShowHere = () => {
    if (component === "category") {
      return <Category />;
    } else if (component === "stores") {
      return <Stores />;
    } else if (component === "storeProducts") {
      return <StoreProducts />;
    } else if (component === "product") {
      return <ProductPage />;
    } else if (component === "login") {
      return <Login />;
    } else if (component === "aboutcomponent") {
      return <AboutComponent />;
    } else if (component === "search") {
      return <Search />;
    } else if (component === "wishlist") {
      return <WishList />;
    } else if (component === "compareitems") {
      return <CompareItems />;
    } else if (component === "cookiepage") {
      return <CookiePage />;
    } else if (component === "messages") {
      return <Messaging />;
    } else if (component === "staticContent") {
      return <StaticContent />;
    } else if (component === "ComingSoon") {
      return <Comingsoon />;
    } else {
      return <Main component={component} />;
    }
  };

  return (
    <React.Fragment>
      <div>
        <MenuTopBar />
        <LocationBar />

        {/* <NavMenu /> */}
        <PromotionComponent />
        <Compare />
      </div>

      {handleWhatComponentToShowHere()}

      <MailchimpSub />
    </React.Fragment>
  );
}
