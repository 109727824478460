import React, { useContext } from "react";
import "./styles/FTbtn.css";
import { I18nContext } from "../../i18n";

function FTbtn(props) {
  const { translate } = useContext(I18nContext);

  let switch1;
  let switch2;

  if (props.onState) {
    switch1 = "switch1-on";
    switch2 = "switch2-off";
  } else {
    switch1 = "switch1-off";
    switch2 = "switch2-on";
  }

  return (
    <div className="outBox">
      <label onClick={props.toggle}>
        <span className={switch1}>{translate("cookiePage.switchOnText")}</span>
      </label>

      <label onClick={props.toggle}>
        <span className={switch2}>{translate("cookiePage.switchOffText")}</span>
      </label>
    </div>
  );
}

export default FTbtn;
