import React from "react";
import ReactFormValidation from "react-form-input-validation";
import Grid from '@material-ui/core/Grid';

class MerchantRegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        customer_name: "",
        company_name: "",
        business_address: "",
        email_address: "",
        phone_number: "",
        website_address: "",
        facebook_page: "",
        types_of_products: "",
        products_wehave: "",
        referred_by: "",
        taxi: "",
        pickup_place: "",
        comments: "",
        dropoff_place: "",
        heard_about: []
      },
      errors: {}
    };
    this.form = new ReactFormValidation(this, { locale: "en" });
    this.form.useRules({
      customer_name: "required|username_available",
      company_name: "required|companyname",
      business_address: "required|businessaddress",
      email_address: "required|email",
      website_address: "required|websiteaddress",
      facebook_page: "required|facebookpage",
      types_of_products: "required|typesofproducts",
      products_wehave: "required|productswehave",
      phone_number: "required|numeric|digits_between:10,12",
      referred_by: "required|referredby",
      taxi: "required",
      pickup_place: "required",
      comments: "required|max:20",
      dropoff_place: "required",
      heard_about: "required|array"
    });

    this.form.onformsubmit = (fields) => {
      console.log(fields);
    }

    ReactFormValidation.registerAsync('username_available', function(username, attribute, req, passes) {
      setTimeout(() => {
        if (username === "foo")
          passes(false, 'Username has already been taken.'); // if username is not available
        else
          passes();
      }, 1000);
    });
    /* let messages = ReactFormValidation.getMessages('en');
    messages.required = 'Whoops, :attribute field is required.';
    ReactFormValidation.setMessages('en', messages);
    ReactFormValidation.useLang('en') */
  }

  render() {
    return (
      <div>
        <h1 className="contactHead">Merchant Register Form</h1>
      
        <div className="MerchantRegisterForm" style={{}}>

            {/* <form noValidate autoComplete="off">
              <TextField id="outlined-basic" label="Outlined" variant="outlined" />
            </form> */}

            
          
          <form
            className="myForm"
            noValidate
            autoComplete="off"
            onSubmit={this.form.handleSubmit}
          >

          <Grid container spacing={3}>

          <Grid item xs={5}>
            <div className="LabelData">Name</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="customer_name"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.customer_name}
                  // To override the attribute name
                  data-attribute-name="Customer Name"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.customer_name
                  ? this.state.errors.customer_name
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Company Name</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="company_name"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.company_name}
                  // To override the attribute name
                  data-attribute-name="Company Name"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.company_name
                  ? this.state.errors.company_name
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Business Address</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="business_address"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.business_address}
                  // To override the attribute name
                  data-attribute-name="Business Address"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.business_address
                  ? this.state.errors.business_address
                  : ""}
              </label></div>
          </Grid>



          <Grid item xs={5}>
            <div className="LabelData">Phone</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="tel"
                  name="phone_number"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.phone_number}
                />
                
                <label className="error">
                {this.state.errors.phone_number
                  ? this.state.errors.phone_number
                  : ""}
              </label></div>
          </Grid>






          <Grid item xs={5}>
            <div className="LabelData">Email</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="email"
                  name="email_address"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.email_address}
                />
                
                <label className="error">
                {this.state.errors.email_address
                  ? this.state.errors.email_address
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Website</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="website_address"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.website_address}
                  // To override the attribute name
                  data-attribute-name="Website"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.website_address
                  ? this.state.errors.website_address
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Facebook Page</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="facebook_page"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.facebook_page}
                  // To override the attribute name
                  data-attribute-name="Facebook Page"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.facebook_page
                  ? this.state.errors.facebook_page
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Product Types or Services You Offer</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="types_of_products"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.types_of_products}
                  // To override the attribute name
                  data-attribute-name="types of products"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.types_of_products
                  ? this.state.errors.types_of_products
                  : ""}
              </label></div>
          </Grid>


          <Grid item xs={5}>
            <div className="LabelData">Total Products To Sell</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput">
              
              <select type="select" name="products_wehave"
                  value={this.state.fields.products_wehave}
                  onChange={this.form.handleChangeEvent}
                  onBlur={this.form.handleBlurEvent}>
              <option></option>
              <option>0 - 10 Products</option>
              <option>11 - 50 Products</option>
              <option>51 - 100 Products</option>
              <option>111 - 500 Products</option>
              <option>501 - Above Products</option>
              </select>
              
             
              <label className="error">
                {this.state.errors.products_wehave
                  ? this.state.errors.products_wehave
                  : ""}
              </label></div>
          </Grid>



          

          



          <Grid item xs={5}>
            <div className="LabelData">How Did You Hear About Us</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><fieldset>
              
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="heard_about"
                    onChange={this.form.handleChangeEvent}
                    value="baby"
                  />{" "}
                  Google Ads{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="heard_about"
                    onChange={this.form.handleChangeEvent}
                    value="wheelchair"
                  />{" "}
                  Facebook{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="heard_about"
                    onChange={this.form.handleChangeEvent}
                    value="tip"
                  />{" "}
                  Friends{" "}
                </label>
              </p>
              <label className="error">
                {this.state.errors.heard_about
                  ? this.state.errors.heard_about
                  : ""}
              </label>
            </fieldset></div>
          </Grid>

          

          

          <Grid item xs={5}>
            <div className="LabelData">Referred By</div>
          </Grid>
          <Grid item xs={7}>
            <div className="LabelInput"><input
                  type="text"
                  name="referred_by"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.referred_by}
                  // To override the attribute name
                  data-attribute-name="Referred By"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.referred_by
                  ? this.state.errors.referred_by
                  : ""}
              </label></div>
          </Grid>
          



<div className="merchantregister">
          <Grid item xs={12}>
              <button style={{
                width: "100%",
                margin: "30px 0px 10px 0px",
                float: "left",
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: "15px",
                background: "#1f8742"
              }} type="submit" className="btn waves-effect waves-light btn-light-dark">Submit</button>
          </Grid>
          </div>
          

            
          </Grid>
            
            

            
            

            

            

            

            

            
          </form>
        </div>
        </div>
    );
  }
}

export default MerchantRegisterForm;
