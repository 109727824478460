import React, { useState, useEffect, useRef } from "react";
import { PREVIEW } from "../../../project-config.js";
import history from "../../../history.js";
import classes from "../Styles/SearchBarMobile.module.css";
import { I18nContext } from "../../../i18n/index";

export default function SearchBarMobile({ setNavMenuOpen }) {
  const { translate } = React.useContext(I18nContext);
  const [input, setInput] = useState("");

  const handleEnterKeyPressed = event => {
    console.info(event.keyCode);
    if (event.keyCode == 13) {
      handleSearchIconClicked();
      setNavMenuOpen(false);
    }
  };

  const handleSearchIconClicked = () => {
    if (input.trim() !== "") {
      history.push(`${PREVIEW}/search/${input}`);
      setNavMenuOpen(false);
    }
  };

  const handleInputChanged = event => {
    setInput(event.target.value);
  };

  const handleClearInput = () => {
    setInput("");
  };

  return (
    <div className={`icon-container ${classes.searchIcon}`}>
      <div className={`icon-wrapper ${classes.iconWrapper}`}>
        <React.Fragment>
          <i
            onClick={handleClearInput}
            style={{ visibility: input.length > 0 ? "visible" : "hidden" }}
            className="material-icons"
          >
            close
          </i>
          <input
            value={input}
            onChange={handleInputChanged}
            onKeyDown={handleEnterKeyPressed}
            placeholder={translate("Searchforproduct")}
            type="text"
            className={classes.input}
          />
        </React.Fragment>

        <i className="material-icons" onClick={handleSearchIconClicked}>
          search
        </i>
      </div>
    </div>
  );
}
