import React, { useContext, useEffect } from "react";
import { PREVIEW } from "./project-config.js";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet";

//TEST ADDED21121231233
import history from "./history.js";
import ReactGA from "react-ga";

import { IntlProvider } from "react-intl";

import "./assets/css/menu.css";
import "./assets/css/minified.css";
import "./assets/css/avetti.css";
import "./assets/css/mailchimp.css";
import Menu from "./header/Menu.jsx";
//import Playground from "./components/Playground/Playground.jsx";

import "./assets/css/sl.css";
import "./assets/css/b2b2c.css";
import "./assets/css/fontello.css";
import "./assets/jss/demo-ar.scss";

import { staticContents } from "./components/AC-StaticPages/StaticRoutes.js";

import Comingsoon from "../src/content/ComingSoon";
import ContactUs from "../src/content/ContactUs";
import MerchantRegister from "../src/content/MerchantRegister";

import {
  fetchMenu,
  fetchByStyle,
  setStoresNavCatAction
} from "./redux/actions/menuActions.js";
import {
  fetchFeatured,
  changeLanguageFromFunction,
  fetchCurrencyInfo,
  changeLanguage,
  changeCountry,
  updateCurrency
} from "./redux/actions/mainActions.js";
import {
  fetchLogin,
  fetchUserData,
  loginUserDataInitial
} from "./redux/actions/loginActions.js";

import changeUrlwithLanguageChange from "./i18n/functions/changeUrlwithLanguageChange";
import {
  I18nContext,
  translations,
  getLanguageServer,
  countryList,
  mapCountryList,
  getCountryCode,
  currencyList,
  currencyDefine
} from "./i18n/index";
import { setGeoLocationState } from "./redux/actions/geoLocationActions.js";

ReactGA.initialize("UA-152408843-1");

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default function App() {
  // Build5Test file
  //23
  const { langCode, dispatchContext, currency } = useContext(I18nContext);

  const countryCode = useSelector(
    state => state.mainReducer.country,
    shallowEqual
  );

  const dispatch = useDispatch(); //this hook gives us dispatch method
  const entryState = useSelector(
    state => state.mainReducer.entryState,
    shallowEqual
  );
  const languageButtonState = useSelector(
    state => state.mainReducer.languageButton,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const currencyState = useSelector(
    state => state.mainReducer.currency,
    shallowEqual
  );

  const loginInfoState = useSelector(
    state => state.loginReducer.userInfo,
    shallowEqual
  );

  useEffect(() => {
    if (!loginInfoState.default) {
      let countryCode = getCountryCode(
        loginInfoState.countryCode.toLowerCase()
      );

      localStorage.setItem("language", getLanguageServer(countryCode));
      localStorage.setItem("country", countryCode);
      dispatch(changeCountry(countryCode));
      dispatch(changeLanguage(getLanguageServer(countryCode)));
      dispatchContext({
        type: "setThroughServer",
        payload: countryCode
      });

      if (!window.location.pathname.includes(`/${langCode}/`)) {
        changeUrlwithLanguageChange(langCode, entryState);
      }
    }
  }, [loginInfoState]);

  //Change the directurlaccess state to false and determine which language to load...
  if (entryState === true && !localStorage.getItem("language")) {
    let temp = window.location.pathname.split("/").filter(temp => {
      if (temp != "" && temp != "preview") {
        return true;
      } else {
        return false;
      }
    })[0];
    console.info("temp", temp);
    if (
      !mapCountryList.filter(trans => {
        if (trans === temp) {
          return true;
        } else {
          return false;
        }
      }).length > 0
        ? true
        : false
    ) {
      temp = "en";
    }
    dispatchContext({ type: "setLanguage", payload: temp });
  }
  useEffect(() => {
    console.info("GIRDI APP");
    let languageStorage = localStorage.getItem("language");
    if (!languageStorage) {
      localStorage.setItem("language", languageState);
      languageStorage = "en";
    }
    let countryStorage = localStorage.getItem("country");
    if (!countryStorage) {
      countryStorage = "global";
      localStorage.setItem("country", countryStorage);
    }
    let currencyStorage = localStorage.getItem("currency");
    if (!currencyStorage) {
      localStorage.setItem("currency", currencyState);
      currencyStorage = currencyDefine(countryStorage);
    }
    dispatchContext({ type: "setCurrencyOnly", payload: currencyStorage });

    dispatch(changeCountry(countryStorage));
    if (
      !window.location.pathname.includes(
        `/${localStorage.getItem("language")}/`
      )
    ) {
      changeUrlwithLanguageChange(langCode, entryState);
    }
    if (localStorage.getItem("language") !== langCode) {
      dispatchContext({
        type: "setOnlyLanguage",
        payload: localStorage.getItem("language")
      });
    }

    dispatch(changeLanguageFromFunction(languageStorage));
    if (
      !window.location.pathname.includes(
        `/${localStorage.getItem("language")}/`
      )
    ) {
      dispatchContext({
        type: "setOnlyLanguage",
        payload: localStorage.getItem("language")
      });
      dispatch(fetchMenu(window.location, languageStorage));
    } else {
      dispatch(fetchMenu(window.location, languageStorage));
    }
    //dispatch(fetchByStyle());
    dispatch(fetchLogin());

    let userDataStorage = localStorage.getItem("userLocation");
    console.info("userDataStorage", userDataStorage);
    if (!userDataStorage || userDataStorage.length < 10) {
      dispatch(fetchUserData("", langCode));
    } else {
      dispatch(
        loginUserDataInitial(JSON.parse(localStorage.getItem("userLocation")))
      );
    }

    let geoLocationStorage = localStorage.getItem("userLocationGoogleAPI");
    if (!geoLocationStorage || geoLocationStorage.length < 10) {
      // TODO
    } else {
      let geoLocationObj = JSON.parse(geoLocationStorage);
      geoLocationObj.initial = true;
      dispatch(setGeoLocationState(geoLocationObj));
    }

    dispatch(updateCurrency(currencyStorage));
    //dispatch(fetchBasket());
    //dispatch(fetchCurrencyInfo());
  }, []);

  useEffect(() => {
    let languageStorage = localStorage.getItem("language");
    let count = 0;
    if (!entryState && languageButtonState && count == 0) {
      count++;
      console.info("girdi555 kackere", count, window.location.pathname);

      dispatch(fetchMenu(window.location, languageStorage));
      changeUrlwithLanguageChange(langCode, entryState);
    }
    return () => {
      count = 0;
    };
  }, [langCode, countryCode]);

  /*  useEffect(() => {
    let languageStorage = localStorage.getItem("language");

    if (!entryState && languageButtonState) {
      dispatch(fetchMenu(window.location, languageStorage));
    }
    if (
      !entryState &&
      languageButtonState &&
      !window.location.pathname.includes(`${langCode}/`)
    ) {
      console.info("girdi55", window.location.pathname);

      changeUrlwithLanguageChange(langCode, entryState);
    }
  }, [countryCode]);
 */
  return (
    <IntlProvider locale={langCode}>
      <Helmet
        titleTemplate="%s - Open For Business Marketplace"
        defaultTitle="Open For Business Marketplace"
      >
        <meta name="description" content="Open For Business Marketplace" />
      </Helmet>

      <Router history={history}>
        <div className={langCode === "ar" ? "rtl" : ""}>
          <Switch>
            <Route
              exact
              path={"/"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="home" />
                  </>
                );
              }}
            />
            <Route
              exact
              path={"/preview"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="home" />
                  </>
                );
              }}
            />
            {staticContents.map(content => {
              return (
                <Route
                  key={content[1]}
                  path={PREVIEW + `/page/${content[1]}`}
                  render={routeProps => (
                    <Menu {...routeProps} component="staticContent" />
                  )}
                />
              );
            })}
            {staticContents.map(content => {
              return (
                <Route
                  key={content[1]}
                  path={PREVIEW + "/" + langCode + `/page/${content[1]}`}
                  render={routeProps => (
                    <Menu {...routeProps} component="staticContent" />
                  )}
                />
              );
            })}
            <Route
              exact
              path={"/Coming-Soon"}
              render={routeProps => <Comingsoon />}
            ></Route>
            <Route
              exact
              path={PREVIEW + "/Contact-Usasasdasd"}
              render={routeProps => <ContactUs />}
            ></Route>
            <Route
              exact
              path={PREVIEW + "/Merchant-Register"}
              render={routeProps => <MerchantRegister />}
            ></Route>
            <Route
              exact
              path={"/" + langCode + "/"}
              render={routeProps => {
                return (
                  <>
                    <Redirect to={"/" + langCode + "/"} />
                    <Menu {...routeProps} component="home" />
                  </>
                );
              }}
            />
            <Route
              exact
              path={"/preview/" + langCode}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="home" />
                  </>
                );
              }}
            />
            <Route
              exact
              path={PREVIEW + "/login/"}
              render={routeProps => <Menu {...routeProps} component="login" />}
            />
            <Route
              exact
              path={PREVIEW + "/" + langCode + "/login/"}
              render={routeProps => <Menu {...routeProps} component="login" />}
            />
            <Route
              exact
              path={PREVIEW + "/about/"}
              render={routeProps => (
                <Menu {...routeProps} component="aboutcomponent" />
              )}
            />
            <Route
              exact
              path={PREVIEW + "/" + langCode + "/about/"}
              render={routeProps => (
                <Menu {...routeProps} component="aboutcomponent" />
              )}
            />
            <Route
              path={PREVIEW + "/wishlist"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="wishlist" />
                  </>
                );
              }}
            />
            {/* STORE ROUTE */}
            <Route
              path={PREVIEW + "/shop/stores"}
              render={routeProps => <Menu {...routeProps} component="stores" />}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/shop/stores"}
              render={routeProps => <Menu {...routeProps} component="stores" />}
            />
            {mapCountryList.map((lang, index) => {
              return (
                <Route
                  key={index}
                  path={PREVIEW + "/" + lang + "/shop/stores"}
                  render={routeProps => (
                    <Menu {...routeProps} component="stores" />
                  )}
                />
              );
            })}
            <Route
              path={PREVIEW + "/stores/"}
              render={routeProps => (
                <Menu {...routeProps} component="storeProducts" />
              )}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/stores/"}
              render={routeProps => (
                <Menu {...routeProps} component="storeProducts" />
              )}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/wishlist"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="wishlist" />
                  </>
                );
              }}
            />
            <Route
              exact
              path={PREVIEW + "/search/:key"}
              render={routeProps => <Menu {...routeProps} component="search" />}
            />
            <Route
              exact
              path={PREVIEW + "/" + langCode + "/search/:key"}
              render={routeProps => <Menu {...routeProps} component="search" />}
            />
            <Route
              path={PREVIEW + "/cookiepage"}
              render={routeProps => (
                <Menu {...routeProps} component="cookiepage" />
              )}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/cookiepage"}
              render={routeProps => (
                <Menu {...routeProps} component="cookiepage" />
              )}
            />
            <Route
              path={PREVIEW + "/shop/"}
              render={routeProps => (
                <Menu {...routeProps} component="category" />
              )}
            />
            {mapCountryList.map((lang, index) => {
              return (
                <Route
                  key={index}
                  path={PREVIEW + "/" + lang + "/shop/"}
                  render={routeProps => (
                    <Menu {...routeProps} component="category" />
                  )}
                />
              );
            })}
            <Route
              path={PREVIEW + "/demo/shop/"}
              render={routeProps => (
                <Menu {...routeProps} component="category" />
              )}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/demo/shop/"}
              render={routeProps => (
                <Menu {...routeProps} component="category" />
              )}
            />
            <Route
              path={PREVIEW + "/compareitems"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="compareitems" />
                  </>
                );
              }}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/compareitems"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="compareitems" />
                  </>
                );
              }}
            />
            <Route
              path={PREVIEW + "/messages"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="messages" />
                  </>
                );
              }}
            />
            <Route
              path={PREVIEW + "/" + langCode + "/messages"}
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="messages" />
                  </>
                );
              }}
            />
            {/*   <Route
              path={PREVIEW + "/" + langCode + "/playground"}
              render={routeProps => <Playground />}
            /> */}
            <Route
              path={PREVIEW + "/"}
              render={routeProps => {
                let new404Logic =
                  history.location.pathname.split("/")[
                    history.location.pathname.split("/").length - 1
                  ] !== "" &&
                  history.location.pathname.split("/")[
                    history.location.pathname.split("/").length - 2
                  ] !== ""
                    ? true
                    : false;
                return (
                  <>
                    {new404Logic ? (
                      <Menu
                        langCode={langCode}
                        {...routeProps}
                        component="product"
                      />
                    ) : (
                      <>
                        <Menu {...routeProps} component="home" />
                      </>
                    )}
                  </>
                );
              }}
            />
            {/*   <Route
              path={PREVIEW + "/" + langCode + "/"}
              render={routeProps => {
                return (
                  <>
                    <Menu
                      langCode={langCode}
                      {...routeProps}
                      component="product"
                    />
                  </>
                );
              }}
            /> */}
            <Route
              render={routeProps => {
                return (
                  <>
                    <Menu {...routeProps} component="home" />
                  </>
                );
              }}
            />
          </Switch>
        </div>
      </Router>
    </IntlProvider>
  );
}
