import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";
import LazyLoad from "react-lazyload";
import "./Styles/ImageCarousel.css";
import { setProductImagecarouselInitialAction } from "../../../../redux/actions/productAction";
import { usePrevious } from "../../../../functions/Utilities";

const areEqual = (prevProps, nextProps) => {
  console.info("borop rerender", nextProps);
  return nextProps.productImageSwitch == false;
};

const ImageCarousel = React.memo(function ImageCarousel(props) {
  const dispatch = useDispatch();
  const [mainImage, setMainImage] = useState("");

  const [mainImageUrl, setMainImageUrl] = useState(
    "https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/"
  );

  const [wholeImageUrl, setWholeImageUrl] = useState("");
  const [galleryImagesCount, setGalleryImagesCount] = useState([]);
  const [sysNumImages, setSysNumImages] = useState(0);

  const productImageSwitchState = props.productImageSwitch;

  const productPageImageCarouselInitialState = useSelector(
    state => state.productReducer.productPageImageCarouselInitial,
    shallowEqual
  );

  console.info(
    "farop state",
    productImageSwitchState,
    productPageImageCarouselInitialState,
    wholeImageUrl
  );

  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const loadingState = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const itemTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const previousItemIdState = usePrevious(
    Object.assign({}, { itemIdState, itemTitleState })
  );

  const propertiesState = useSelector(
    state => state.productReducer.itemDetail.properties,
    shallowEqual
  );

  console.info("borop rerender2", productImageSwitchState);

  useEffect(() => {
    console.info("farop prev", previousItemIdState, itemIdState);
    if (
      previousItemIdState &&
      previousItemIdState.itemIdState != undefined &&
      previousItemIdState.itemIdState != itemIdState
    ) {
      if (previousItemIdState.itemTitleState == itemTitleState)
        dispatch(setProductImagecarouselInitialAction(false));
    }
  }, [previousItemIdState]);

  useEffect(() => {
    if (productImageSwitchState || productPageImageCarouselInitialState) {
      console.info("borop rerender3", productImageSwitchState);

      setMainImage(productCode);
    }
  }, [productCode]);

  const handleImageChange = (img, condition) => {
    console.info("handleImageChange", img, condition);
    setMainImage(img);

    if (condition) {
      setMainImageUrl(
        "https://demob2b2c.avetti.io/preview/store/20180521148/assets/images/galleries/"
      );
    } else {
      setMainImageUrl(
        "https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/"
      );
    }
  };

  useEffect(() => {
    if (productImageSwitchState || productPageImageCarouselInitialState) {
      setMainImageUrl(
        "https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/images/"
      );
    }
  }, [itemIdState]);

  useEffect(() => {
    if (
      mainImage &&
      mainImageUrl
      /*   (mainImage && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState) */
    ) {
      setWholeImageUrl(`${mainImageUrl}${mainImage}.jpg`);
    }
  }, [mainImage, mainImageUrl]);

  useEffect(() => {
    console.info("borop image", productImageSwitchState, propertiesState);
    if (
      (propertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState)
    ) {
      let tempNumber = propertiesState.filter(prop => {
        if (prop.propname === "Sys_Gallery_Images") {
          return true;
        } else {
          return false;
        }
      });

      tempNumber = tempNumber && tempNumber[0] && tempNumber[0].propvalue;
      let tempArray = [];

      for (let i = 1; i < Number(tempNumber); i++) {
        tempArray.push(i);
      }
      console.info("tempArray", tempArray);

      setGalleryImagesCount(tempArray);
    }
  }, [propertiesState]);

  useEffect(() => {
    console.info("borop image2", productImageSwitchState);

    if (
      (hiddenPropertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && hiddenPropertiesState)
    ) {
      let prop = hiddenPropertiesState.find(prop => {
        return prop.propname == "Sys_Num_Images";
      });
      if (parseInt(prop.propvalue) >= 1) {
        setSysNumImages(parseInt(prop.propvalue));
      }
    }
  }, [hiddenPropertiesState]);

  let renderMagnifier = "";

  if (isMobileState && wholeImageUrl) {
    renderMagnifier = (
      <Magnifier
        className="input-position"
        imageSrc={`${wholeImageUrl}`}
        imageAlt={`${productInitialState.title}`}
        largeImageSrc={
          mainImageUrl.includes("demob2b2c.avetti")
            ? `${wholeImageUrl}.jpg`
            : wholeImageUrl.replace("images", "largeimages")
        }
        mouseActivation={MOUSE_ACTIVATION.CLICK}
        touchActivation={TOUCH_ACTIVATION.TAP}
        dragToMove={true}
      />
    );
  } else if (wholeImageUrl) {
    renderMagnifier = (
      <MagnifierContainer className="magnifier-container">
        <div className="magnifier-preview-wrapper">
          <MagnifierPreview
            className="magnifier-preview"
            imageSrc={wholeImageUrl}
            imageAlt={`${productInitialState.title}`}
          />
        </div>
        <div className="magnifier-zoom-wrapper">
          <MagnifierZoom
            className="magnifier-zoom"
            imageSrc={
              mainImageUrl.includes("demob2b2c.avetti")
                ? wholeImageUrl
                : wholeImageUrl.replace("images", "largeimages")
            }
          />
        </div>
      </MagnifierContainer>
    );
  }

  const renderGalleryImages = () => {
    console.info("gallery images", galleryImagesCount, sysNumImages);
    if (galleryImagesCount.length > 0 && mainItemIdState == 0) {
      return galleryImagesCount.map(num => (
        <li key={num}>
          <LazyLoad>
            <img
              className="img-thumb"
              src={`https://demob2b2c.avetti.io/preview/store/20180521148/assets/images/galleries/${productCode}-${num}.jpg`}
              alt=""
              style={{
                border: "1px solid #333"
              }}
              onClick={() => {
                console.info("galley image clicked", productCode);
                handleImageChange(`${productCode}-${num}`, "gallery");
              }}
            />
          </LazyLoad>
        </li>
      ));
    } else if (sysNumImages > 0) {
      return [...Array(sysNumImages)].map((e, num) => {
        if (num > 0) {
          return (
            <li key={num}>
              <LazyLoad>
                <img
                  className="img-thumb"
                  src={`https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/small/${productCode}-${
                    num + 1
                  }.jpg`}
                  alt=""
                  style={{
                    border: "1px solid #333"
                  }}
                  onClick={() => {
                    console.info("sysnum image clicked", productCode);

                    handleImageChange(`${productCode}-${num + 1}`);
                  }}
                />
              </LazyLoad>
            </li>
          );
        }
      });
    }
  };
  console.info("imageCarousel", galleryImagesCount.length, " ", sysNumImages);
  console.info("farop img check", loadingState, wholeImageUrl, itemIdState);
  if (true) {
    return (
      <div id="imageGrid">
        <div className="preview" id="item-img">
          {renderMagnifier}
        </div>
        <div id="product_carousel">
          <div className="controls dis-none">
            <a href="" className="prev">
              <span className="glyphicon glyphicon-arrow-left"></span>
            </a>
            <a href="" className="next">
              <span className="glyphicon glyphicon-arrow-right"></span>
            </a>
          </div>
          <div
            className="jcarousel thumbs"
            id="jcarousel-thumbs"
            data-jcarousel="true"
          >
            <ul
              style={{
                left: "0px",
                top: "0px",
                display:
                  galleryImagesCount.length == 0 && sysNumImages == 1
                    ? "none"
                    : ""
              }}
            >
              <li>
                <LazyLoad>
                  <img
                    className="img-thumb"
                    src={`https://s3.ca-central-1.amazonaws.com/demob2b2cs3.avetti.ca/store/20180522154/assets/items/small/${productCode}.jpg`}
                    alt=""
                    style={{
                      border: "1px solid #333"
                    }}
                    onClick={() => handleImageChange(productCode)}
                  />
                </LazyLoad>
              </li>

              {renderGalleryImages()}
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="imageGrid">
        <div className="preview" id="item-img">
          <span id="itemImg22" style={{ display: "inline-block" }}>
            <LazyLoad>
              <img
                src={productInitialState.image}
                className="img-responsive"
                style={{
                  cursor: "pointer",
                  float: "left",
                  border: "1px solid #333"
                }}
                title={productInitialState.title}
              />
            </LazyLoad>
          </span>
        </div>
      </div>
    );
  }
}, areEqual);

export default ImageCarousel;
