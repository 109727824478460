import React from "react";
import { PROJECT_LINK, VID } from "../project-config";
import { I18nContext } from "../i18n/index";
import MenuTopBar from "../header/MenuTopBar";
import ContactForm from "../content/Form";
import Footer from "../components/AC-Footer/Footer";
import Async from "react-code-splitting";

const LocationBar = () => (
  <Async load={import("../components/AC-Location/LocationBar.jsx")} />
);

const ContactFormFunction = () => {
  

  return (
    <>
      <MenuTopBar />
      <LocationBar />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ContactFormFunction;
