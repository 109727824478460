import gql from 'graphql-tag';

export const GET_NODE_BY_URL =  gql`
query getNodeByURL($path: String!) {
    route: route(path: $path) {
      ... on EntityCanonicalUrl {
        entity {
          ... on NodeArticle {
            nid
            entityLabel
            body {
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_NODE_PAGE_BY_URL =  gql`
    query getNodeByURL($path: String!) {
        route: route(path: $path) {
            ... on EntityCanonicalUrl {
                entity {
                    ... on NodePage {
                    nid
                    entityLabel
                        body {
                            value
                        }
                    }
                }
            }
        }
    }
`;