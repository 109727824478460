import React from "react";
import ReactFormValidation from "react-form-input-validation";
import Grid from '@material-ui/core/Grid';

class ValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        customer_name: "",
        email_address: "",
        phone_number: "",
        comments: ""
      },
      errors: {}
    };
    this.form = new ReactFormValidation(this, { locale: "en" });
    this.form.useRules({
      customer_name: "required|username_available",
      email_address: "required|email",
      phone_number: "required|numeric|digits_between:10,12",
      comments: "required|max:20"
    });

    this.form.onformsubmit = (fields) => {
      console.log(fields);
    }

    ReactFormValidation.registerAsync('username_available', function(username, attribute, req, passes) {
      setTimeout(() => {
        if (username === "foo")
          passes(false, 'Username has already been taken.'); // if username is not available
        else
          passes();
      }, 1000);
    });
    /* let messages = ReactFormValidation.getMessages('en');
    messages.required = 'Whoops, :attribute field is required.';
    ReactFormValidation.setMessages('en', messages);
    ReactFormValidation.useLang('en') */
  }

  render() {
    return (
      <div>
        <h1 className="contactHead">Contact Form</h1>
      
        <div className="ContactForm" style={{}}>

            {/* <form noValidate autoComplete="off">
              <TextField id="outlined-basic" label="Outlined" variant="outlined" />
            </form> */}

            
          
          <form
            className="myForm"
            noValidate
            autoComplete="off"
            onSubmit={this.form.handleSubmit}
          >

          <Grid container spacing={3}>

          <Grid item xs={4}>
            <div className="LabelData">Name</div>
          </Grid>
          <Grid item xs={8}>
            <div className="LabelInput"><input
                  type="text"
                  name="customer_name"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.customer_name}
                  // To override the attribute name
                  data-attribute-name="Customer Name"
                  data-async
                />
                
                <label className="error">
                {this.state.errors.customer_name
                  ? this.state.errors.customer_name
                  : ""}
              </label></div>
          </Grid>



          <Grid item xs={4}>
            <div className="LabelData">Phone</div>
          </Grid>
          <Grid item xs={8}>
            <div className="LabelInput"><input
                  type="tel"
                  name="phone_number"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.phone_number}
                />
                
                <label className="error">
                {this.state.errors.phone_number
                  ? this.state.errors.phone_number
                  : ""}
              </label></div>
          </Grid>






          <Grid item xs={4}>
            <div className="LabelData">Email</div>
          </Grid>
          <Grid item xs={8}>
            <div className="LabelInput"><input
                  type="email"
                  name="email_address"
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                  value={this.state.fields.email_address}
                />
                
                <label className="error">
                {this.state.errors.email_address
                  ? this.state.errors.email_address
                  : ""}
              </label></div>
          </Grid>



          <Grid item xs={4}>
            <div className="LabelData">Message</div>
          </Grid>
          <Grid item xs={8}>
            <div className="LabelInput"><textarea
                  name="comments"
                  maxLength="20"
                  value={this.state.fields.comments}
                  onChange={this.form.handleChangeEvent}
                  onBlur={this.form.handleBlurEvent}
                ></textarea>
                
                <label className="error">
                {this.state.errors.comments
                  ? this.state.errors.comments
                  : ""}
              </label></div>
          </Grid>

          <div className="merchantregister">
          <Grid item xs={12}>
              <button style={{
                width: "100%",
                margin: "30px 0px 10px 0px",
                float: "left",
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: "15px",
                background: "#1f8742"
              }} type="submit" className="btn waves-effect waves-light btn-light-dark">Submit</button>
          </Grid>
          </div>

          

            
          </Grid>
            
            

            
            {/* <fieldset>
              <legend>Which taxi do you require?</legend>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="radio"
                    name="taxi"
                    onChange={this.form.handleChangeEvent}
                    value="car"
                  />{" "}
                  Car{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="radio"
                    name="taxi"
                    onChange={this.form.handleChangeEvent}
                    value="van"
                  />{" "}
                  Van{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="radio"
                    name="taxi"
                    onChange={this.form.handleChangeEvent}
                    value="tuk tuk"
                  />{" "}
                  Tuk Tuk{" "}
                </label>
              </p>
              <label className="error">
                {this.state.errors.taxi
                  ? this.state.errors.taxi
                  : ""}
              </label>
            </fieldset> */}

            {/* <fieldset>
              <legend>Extras</legend>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="extras"
                    onChange={this.form.handleChangeEvent}
                    value="baby"
                  />{" "}
                  Baby Seat{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="extras"
                    onChange={this.form.handleChangeEvent}
                    value="wheelchair"
                  />{" "}
                  Wheelchair Access{" "}
                </label>
              </p>
              <p>
                <label className="choice">
                  {" "}
                  <input
                    type="checkbox"
                    name="extras"
                    onChange={this.form.handleChangeEvent}
                    value="tip"
                  />{" "}
                  Stock Tip{" "}
                </label>
              </p>
              <label className="error">
                {this.state.errors.extras
                  ? this.state.errors.extras
                  : ""}
              </label>
            </fieldset> */}

            {/* <p>
              <label>
                Pickup Date
                <input
                  type="date"
                  name="pickup_time"
                  onChange={this.form.handleChangeEvent}
                  onBlur={this.form.handleBlurEvent}
                  value={this.state.fields.pickup_time}
                />
              </label>
              <label className="error">
                {this.state.errors.pickup_time
                  ? this.state.errors.pickup_time
                  : ""}
              </label>
            </p> */}

            {/* <p>
              <label>
                Pickup Place
                <select
                  id="pickup_place"
                  name="pickup_place"
                  value={this.state.fields.pickup_place}
                  onChange={this.form.handleChangeEvent}
                  onBlur={this.form.handleBlurEvent}
                >
                  <option value="">Select One</option>
                  <option value="office">Taxi Office</option>
                  <option value="town_hall">Town Hall</option>
                  <option value="telepathy">We'll Guess!</option>
                </select>
              </label>
              <label className="error">
                {this.state.errors.pickup_place
                  ? this.state.errors.pickup_place
                  : ""}
              </label>
            </p> */}

            {/* <p>
              <label>
                Dropoff Place
                <input
                  type="text"
                  name="dropoff_place"
                  value={this.state.fields.dropoff_place}
                  onChange={this.form.handleChangeEvent}
                  onBlur={this.form.handleBlurEvent}
                  list="destinations"
                />
              </label>

              <datalist id="destinations">
                <option value="Airport" />
                <option value="Beach" />
                <option value="Fred Flinstone's House" />
              </datalist>
              <label className="error">
                {this.state.errors.dropoff_place
                  ? this.state.errors.dropoff_place
                  : ""}
              </label>
            </p> */}

            
          </form>
        </div>
        </div>
    );
  }
}

export default ValidationForm;
