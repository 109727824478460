import React, { useState, useContext, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PREVIEW, PROJECT_LINK, VID } from "../project-config.js";
import history from "../history.js";
import NavMenu from "./NavMenu.jsx";
import Logo from "../assets/img/Logo.png";
import LogoMobile from "../assets/img/demoLogo_mobile.png";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "../components/AC-MiniCart/MiniCart.jsx";
import { cookieWindowShow } from "../../src/redux/actions/loginActions";
import CookieDialog from "../components/AC-Cookie/CookieDialog";
import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import WishList from "../components/AC-WishList/components/WishListCustomModal.jsx";
import LanguageSelect from "../i18n/components/LanguageSelect";
import { I18nContext } from "../i18n/index";
import capitalize from "../functions/capitalize";


import ProfileIcon from "../assets/img/Profile.png";
import Location from "../assets/img/location.png";
import WishListIcon from "../assets/img/whislist.png";
import Bag from "../assets/img/bag.png";
import More from "../assets/img/More.png";


export default function MenuTopBar() {
  const dispatch = useDispatch();
  const { translate } = useContext(I18nContext);

  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const basketCountState = useSelector(
    state => state.sessionReducer.basket.itemsCount,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  
  let name;

  const toggleDrawerMini = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    setState({ ...state, [side]: open });
  };


  

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart close={() => toggleDrawerMini(side, false)} />
    </div>
  );

  const handleSubmit = evt => {
    evt.preventDefault();
    history.push(`${PREVIEW}/search/${name}`);
    /*alert(`Submitting Name ${name}`);*/
  };

  const handleIconClick = url => {
    history.push(`${PREVIEW}/${url}`);
  };

  const handleLoginIconClicked = event => {
    event.stopPropagation();
    console.info(event.target);
    if (loginNameState == "") {
      history.push(
        langCode !== "en"
          ? PREVIEW + "/" + langCode + "/login"
          : PREVIEW + "/login"
      );
    } else {
      setLoginModalOpened(true);
    }
  };

  // Lee
  // ? redux ?? cookieWindowState(null)
  // ???? cookie dialog
  let cookieWindowState = useSelector(
    state => state.loginReducer.cookieWindow,
    shallowEqual
  );


  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    // let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
    /*   console.info("handleCatChange", cid, cat, parents);
    if (cat.includes("&amp;")) {
      catName = cat.replace("&amp;", "&");
    }

    const category = navCatsState.childs.filter(c => {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    //dispatch(changeCategoryName(catName));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    } */
  };

  useEffect(() => {
    // (in Redux) cookieWindow = cookieAcceptance(null)    (in local)

    let decision = localStorage.getItem("cookieAcceptance");
    if (decision) {
      dispatch(cookieWindowShow(decision.isAccept));
    }
  });

  // ??handler???
  // (in Redux) cookieWindow = true
  const changeCookieInRedux = () => {
    dispatch(cookieWindowShow(true));
  };

  // ??handler???
  // (in local)   cookieAcceptance = {"date": , "consent": , "isAccept": }
  const acceptCookieHandler = () => {
    let consent = {
      date: new Date(),
      consent: "Cookies accepted.",
      isAccept: true
    };

    localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
  };

  const acceptCookieAndCloseWindow = () => {
    changeCookieInRedux();
    acceptCookieHandler();
  };

  return (
    <>
       <nav className="nav-extended" style={{ 
      boxShadow: "none",
      background: "#c2ff61",
      padding: "5px 0px"
   }}>
      <div className="nav-wrapper">
        <div className="header-container">
          {cookieWindowState === null &&
          !window.location.pathname.includes("cookiepage") ? (
            <CookieDialog
              destination={
                langCode !== "en"
                  ? `${PREVIEW}/${langCode}/cookiepage`
                  : PREVIEW + "/cookiepage"
              }
              acceptCookieCloseDialog={acceptCookieAndCloseWindow}
            />
          ) : null}
          <div className="logo-container">
            <Link
              to="/Coming-Soon"
              className="brand-logo logo-i"
            >
              <img
                src={isMobileState ? LogoMobile : Logo}
                alt="Avetti eCommerce Logo"
                className="img-responsive"
              />
            </Link>
          </div>
         
          
          
          
          
        </div>
      </div>
    </nav>
    </>
  );
}
