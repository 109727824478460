import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  UPDATE_LOGIN,
  USER_DATA_SUCCESS,
  COOKIE_WINDOW_SHOW,
  USER_DATA_INITIAL
} from "../types.js";

import {
  LOGOUT_LINK,
  LOGIN_CHECK_LINK,
  CATEGORY_FETCH_LINK
} from "../links.js";

export const cookieWindowShow = payload => ({
  type: COOKIE_WINDOW_SHOW,
  payload: payload
});

export const loginSuccess = json => ({
  type: LOGIN_SUCCESS,
  payload: {
    loginName: json.loginname,
    securityToken: json.securitytoken,
    lat: json.latitude,
    lng: json.longitude
  }
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: error
});

export const updateLoginInfo = json => ({
  type: UPDATE_LOGIN,
  payload: {
    loginName: json.loginname,
    securityToken: json.securitytoken,
    lat: json.latitude,
    lng: json.longitude
  }
});

export const fetchLogin = securitytoken => {
  var form = new FormData();
  form.append("securitytoken", securitytoken);

  return dispatch => {
    fetch(LOGIN_CHECK_LINK, {
      headers: {
        Accept: "*/*"
      }
    })
      .then(res => res.json())
      .then(json => {
        console.info("login Success", json);
        dispatch(loginSuccess(json.__Result));
      })
      .catch(error => dispatch(loginFailure(error)));
  };
};

export const loginUserDataSuccess = data => ({
  type: USER_DATA_SUCCESS,
  payload: data
});

export const loginUserDataInitial = data => ({
  type: USER_DATA_INITIAL,
  payload: data
});

export const fetchUserData = (securitytoken, langCode) => {
  var form = new FormData();
  form.append("securitytoken", securitytoken);
  return dispatch => {
    fetch("https://ipapi.co/json", {
      headers: {
        Accept: "*/*"
      }
    })
      .then(res => res.json())
      .then(json => {
        console.info("ipapi", json);
        let location = {
          city: json.city,
          countrycode: json.country_code,
          countryname: json.country_name,
          latitude: json.latitude,
          longitude: json.longitude,
          postal: json.postal,
          regioncode: json.region_code,
          regionname: json.region,
          languages: json.languages
        };

        if (json.postal) location.postal = json.postal;

        localStorage.setItem("userLocation", JSON.stringify(location));
        dispatch(loginUserDataSuccess(location));
      })
      .catch(error => {
        console.error("apiapi fetch error");
        alert(
          "Failed to retrieve your location, this might be due to a adblocker. We are not displaying any ads on this site, you may want to consider disabling it because some features on this site depend on it."
        );
        dispatch(loginFailure(error));
      });
  };
};

export const handleLogout = securitytoken => {
  return dispatch => {
    var form = new FormData();
    form.append("securitytoken", securitytoken);

    fetch(LOGOUT_LINK, {
      method: "POST",
      body: form,
      headers: {
        Accept: "*/*",
        credentials: "same-origin"
      },
      mimeType: "multipart/form-data",
      data: form
    }).then(json => {
      dispatch(logoutSuccess());
    });
  };
};
