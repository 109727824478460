import React, { useContext, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

// core components
import Slider from "./Slider";
import SliderMobile from "./Slider_Mobile";
import Footer from "../components/AC-Footer/Footer.jsx";
import StaticContent from "../components/AC-StaticPages/StaticContent.jsx";
import ServicesBox from "./ServicesBox";
import CategoryFeatured from "./CategoryFeatured";
import FeaturedPromotions from "./FeaturedPromotions";

import VendorBanner1 from "./VendorBanner1";
import VendorBanner2 from "./VendorBanner2";
import VendorBanner3 from "./VendorBanner3";
import SubscriptionBox from "./SubscriptionBox";
import BestSellingProduct from "../content/ItemsShow1.jsx";
import FeaturedSellers from "../content/FeaturedSellers.jsx";
import Featured from "../content/ItemsShow2.jsx";
import Collections from "./Collections";

import { I18nContext } from "../i18n/index";

function Main(props) {
  const dispatch = useDispatch();
  const { langCode } = useContext(I18nContext);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  if (props.component === "staticContent") {
    return (
      <React.Fragment>
        <div id="bd">
          <StaticContent />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/*TESTING*/}
        <div id="bd">
          <div className="item">
            <div style={{ padding: "unset" }}>
              <div id="slide">
                <div className="slideshow-container">
                  {isMobileState ? <SliderMobile /> : <Slider />}
                </div>
              </div>
            </div>
            <ServicesBox />

            {/* <BestSellingProduct /> */}
            <Collections />
            <FeaturedSellers />
            {/* <Featured /> */}
            <FeaturedPromotions />
            {/* {navCatsState.childs.slice(0, 4).map((cat, index) => {
              let catName = cat.name.replace("&amp;", "&");
              return (
                <React.Fragment key={index}>
                  <CategoryFeatured
                    catNamesState={navCatsState.childs.slice(0, 4).map(cat => {
                      if (cat.URL.replace("shop/", "").split("/").length > 1) {
                        return cat.URL.replace("shop/", "").split("/")[1];
                      } else if (langCode !== "en") {
                        return cat.URL.replace("shop/", "").split("/")[0];
                      } else {
                        return cat.URL.replace("shop/", "");
                      }
                    })}
                    cid={cat.cid}
                    cat={catName}
                    number={index}
                    products={MostPopularProducts[langCode][cat.cid].slice(
                      0,
                      6
                    )}
                  />
                  {index == 0 ? <VendorBanner1 /> : null}
                  {index == 1 ? <VendorBanner2 /> : null}
                  {index == 2 ? <VendorBanner3 /> : null}
                </React.Fragment>
              );
            })} */}
            {/* <SubscriptionBox /> */}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
