import { SET_HANDLER } from "../types.js";

/**
 *
 * @param {Object} payload
 * Expects {funcName, func} Object
 */
export const setHandlerAction = payload => ({
  type: SET_HANDLER,
  payload: payload
});
