import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Footer from "../../components/AC-Footer/Footer.jsx";
import "../../assets/css/menu.css";
import "../../assets/css/minified.css";
import history from "../../history.js";
import StoreItems from "./components/StoreItems.jsx";
import Facets from "../../components/AC-Facets/Facets.jsx";
import Facets_Mobile from "../../components/AC-Facets/Facets_Mobile.jsx";
import Pagination from "../../components/AC-Pagination/Pagination.jsx";
import NumberOfItems from "../../components/AC-Pagination/NumberofItems.jsx";
import Loading from "../../components/AC-Loading/Loading.jsx";
import Async from "react-code-splitting";
import SortBy from "../../components/AC-SortBy/SortBy.jsx";
import { renderPlaceholderCategoryItems } from "../AC-CategoryPage/components/CategoryItems/CategoryItems.jsx";
import Map from "../../components/AC-Map/ReactMap";
import CategoryHeader from "../AC-CategoryPage/components/CategoryHeader/CategoryHeader.jsx";
import classes from "./Styles/Stores.module.css";
import { I18nContext } from "../../i18n";
import {
  dispatchScroolPage,
  nextPage
} from "../../redux/actions/paginationActions.js";

function Stores() {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();

  const loading = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const numberOfStoresState = useSelector(
    state => state.categoryReducer.categoryItems.length,
    shallowEqual
  );

  const loadingBottom = useSelector(
    state => state.categoryReducer.loadingBottom,
    shallowEqual
  );

  const pagesState = useSelector(
    state => state.categoryReducer.pages,
    shallowEqual
  );

  const currentPageState = useSelector(
    state => state.categoryReducer.currentPage,
    shallowEqual
  );
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const facetsState = useSelector(
    state => state.facetReducer.facets,
    shallowEqual
  );

  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const cidState = useSelector(
    state => state.categoryReducer.cidN,
    shallowEqual
  );

  const handleNextPage = page => {
    if (scroolPageState < pagesState.length || page != 0) {
      dispatch(dispatchScroolPage(page));
      dispatch(nextPage(page, cidState, true));
    } else {
      console.log("End of the pagea");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [scroolPageState]);

  let handleOnScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      handleNextPage(scroolPageState + 1);
    }
  };

  const handleFilterButtonClicked = () => {
    document.getElementById("root").setAttribute("aria-hidden", true);
    setFilterButtonClicked(true);
  };

  const handleFacetContentCloseIconClicked = () => {
    document.getElementById("root").setAttribute("aria-hidden", false);
    setFilterButtonClicked(false);
  };

  const renderMobileFacets = (
    <React.Fragment>
      <button
        className="mobile-filter-button"
        onClick={() => handleFilterButtonClicked()}
      >
        {translate("mobile_facets.filterButtonText")}
        <i className="material-icons">filter_list</i>
      </button>
      <Facets_Mobile
        filterButtonClicked={filterButtonClicked}
        handleFacetContentCloseIconClicked={handleFacetContentCloseIconClicked}
      />
    </React.Fragment>
  );

  const renderFacets = <Facets />;
  if (loading) {
    return (
      <div id="bd">
        <div className="main">
          <CategoryHeader renderedBy="Stores" />
          <Loading />
          <div className={classes.storesMapPlaceholder}></div>
          <div className="row">
            <div className="category-container">
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justify="space-around" alignItems="center">
                    <Grid
                      className="pagination-container"
                      item
                      xs={12}
                      style={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        margin: "0 1%"
                      }}
                    ></Grid>
                    <Grid item xs={12}>
                      {renderPlaceholderCategoryItems()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="bd">
        <div className="main">
          <CategoryHeader renderedBy="Stores" />
          <div
            style={{
              width: "90%",
              margin: "0 auto",
              marginTop: "20px",
              direction: "ltr"
            }}
          >
            <Map />
          </div>
          <div className="row">
            <div className="category-container">
              <Grid container justify="space-between" alignItems="center">
                {/* <Grid item xs={12} className="grid-store-items-container"> */}
                <Grid item xs={12} className="">
                  <Grid container justify="space-around" alignItems="center">
                    <Grid
                      style={{
                        display: numberOfStoresState === 0 ? "none" : ""
                      }}
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      lg={3}
                      className="grid-facets-container"
                    >
                      {isMobileState ? renderMobileFacets : renderFacets}
                    </Grid>
                    <Grid
                      item
                      xs={numberOfStoresState !== 0 ? 6 : 12}
                      sm={numberOfStoresState !== 0 ? 8 : 12}
                      md={numberOfStoresState !== 0 ? 8 : 12}
                      lg={numberOfStoresState !== 0 ? 9 : 12}
                    >
                      <StoreItems />
                      {loadingBottom ? <Loading /> : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Stores;
