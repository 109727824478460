import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getSupplierInfo } from "../../redux/actions/productAction";
import Footer from "../AC-Footer/Footer";
import "../../assets/css/product-sl.css";
import "./Styles/ProductPage.css";
import ImageCarousel from "./Components/ImageCarousel/ImageCarousel";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";
import VendorList from "./Components/Vendors/VendorList";
import ReviewBar from "./Components/Review/ReviewBar";
import NoItem from "../AC-CategoryPage/components/NoItems/NoItems";
import WishListBar from "./Components/WishList/WishListBar";
import Attributes from "./Components/Attributes/Attributes";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
import RecentlyViewedItems from "../../components/AC-RecentlyViewedItems/RecentlyViewedItems";
import ItemCode from "./Components/ItemCode/ItemCode";
import HelmetSeo from "../../shared/components/AC-Helmet/HelmetSeo";
import EnquiryModal from "./Components/AddToCart/Components/Enquiry/EnquiryModal.jsx";
import Reviews from "./Components/Review/components/Reviews";
import CartValidationErrors from "./Components/CartValidationErrors/CartValidationErrors";
import ConfirmDeliveryOptions from "./Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import OutOfStockError from "./Components/CartValidationErrors/OutOfStockError";
import htmldecoder, { htmlDecode } from "../../functions/htmldecoder";

import {
  addRecentItems,
  addRecentViewItemsAction
} from "../../redux/actions/recentlyViewedItemsActions.js";
import CheckBoxAddons from "./Components/CheckBoxAddons/CheckBoxAddons";
import Accessories from "./Components/Accessories/Accessories";
import AccessoryModal from "./Components/Accessories/components/AccessoryModal";
import { I18nContext } from "../../i18n/index";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import Marketplaces from "./Components/Marketplaces/Marketplaces";
import Loading from "../AC-Loading/Loading";
import GoBackButton from "./Components/Breadcrumb/GoBackButton";

const ProductPage = () => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = React.useContext(I18nContext);
  const reviewsContainerRef = useRef();

  const [titleState, setTitleState] = useState("");
  const [enquiryModalState, setEnquiryModalState] = useState(false);
  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  const [supplierInfoState, setSupplierInfoState] = React.useState({});
  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  // used by vendorList component
  const [
    allSuppliersWithDistance,
    setAllSuppliersWithDistance
  ] = React.useState([]);
  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const productImageSwitchState = useSelector(
    state => state.productReducer.productImageSwitch,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const itemDetailsTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const noProductState = useSelector(
    state => state.productReducer.noproduct,
    shallowEqual
  );
  const supplierInfoReducer = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const recentViewItemsState = useSelector(
    state => state.recentlyViewedItemsReducer.recentlyViewedItemsList,
    shallowEqual
  );

  React.useEffect(() => {
    if (
      supplierInfoReducer &&
      supplierInfoReducer[0] &&
      supplierInfoReducer[0].distributorOrder &&
      supplierInfoReducer[0].distributorOrder.length > 0
    ) {
      if (supplierInfoReducer[0].distributorOrder.length > 0) {
        setSupplierInfoState({
          name: supplierInfoReducer[0].distributorOrder[0].name,
          city: supplierInfoReducer[0].distributorOrder[0].city,
          rating: supplierInfoReducer[0].distributorOrder[0].rating,
          rating_count: supplierInfoReducer[0].distributorOrder[0].rating_count
        });
      }
    }
  }, [supplierInfoReducer]);

  useEffect(() => {
    console.info("RENDER++", itemDetailState.code);
    if (itemDetailState.code != "") {
      dispatch(getSupplierInfo(itemDetailState.itemid));
      window.scrollTo(0, 0);
    }
  }, [itemDetailState.code]);

  useEffect(() => {
    if (productInitialStateFromFetch.title != "") {
      /*  if (productInitialState != "") {
        setTitleState(itemDetailsTitleState);
      } else { */
      setTitleState(productInitialStateFromFetch.title);
      /*       } */
    }
  }, [productInitialStateFromFetch /* , itemDetailsTitleState */]);

  // recently viewed items related TEST
  useEffect(() => {
    if (
      itemDetailIdState &&
      priceState &&
      priceState.prices &&
      supplierInfoReducer &&
      supplierInfoReducer[0] &&
      supplierInfoReducer[0].distributorOrder
    ) {
      console.info("BURAYABAKARLAR2", itemDetailIdState, priceState);

      let firstSuppDistId = supplierInfoReducer[0].distributorOrder[0].distid;

      let itemDetailsTemp = { ...itemDetailState };
      let {
        itemId: id,
        title,
        currency_sign = "$",
        image,
        image3: itemLargeImage,
        url = window.location.pathname.replace("/preview", "")
      } = itemDetailsTemp;
      title = title.length > 40 ? title.substr(0, 40).concat(" ...") : title;
      let tempPrice = priceState.prices
        .find(inv => inv.distributorId == firstSuppDistId)
        .price_1.toFixed(2);
      tempPrice = tempPrice.split(".");
      let price = {
        type: "default",
        value: { decimal: tempPrice[1], integer: tempPrice[0] }
      };
      itemLargeImage = itemLargeImage.substring(
        itemLargeImage.indexOf("/store"),
        itemLargeImage.length
      );

      let previouslyAddedItem = recentViewItemsState.filter(
        item => item.id == id
      )[0];

      console.info("BURAYABAKARLAR3", previouslyAddedItem);

      if (previouslyAddedItem) {
        if (previouslyAddedItem.url != url && previouslyAddedItem.id == id) {
          return;
        }
      }

      if (recentViewItemsState.length >= 10) {
        let tempRecentViewItemsState = recentViewItemsState.filter((v, i) => {
          if (i == 0) {
            return false;
          }
          return true;
        });
        console.info("qwerty", tempRecentViewItemsState, recentViewItemsState);
        dispatch(
          addRecentItems([
            ...tempRecentViewItemsState.filter(item => item.id != id),
            { id, title, currency_sign, image, itemLargeImage, price, url }
          ])
        );
      } else {
        dispatch(
          addRecentViewItemsAction(
            id,
            title,
            currency_sign,
            image,
            itemLargeImage,
            price,
            url,
            recentViewItemsState
          )
        );
      }
    }
  }, [supplierInfoReducer, itemDetailIdState, priceState]);

  const handleEnquiryModalOpenClicked = () => {
    setEnquiryModalState(true);
  };

  if (!noProductState) {
    console.info("RENDER---");
    return (
      <React.Fragment>
        <EnquiryModal
          isLoggedIn={loginNameState !== ""}
          enquiryModalState={enquiryModalState}
          setEnquiryModalState={setEnquiryModalState}
        />
        <AccessoryModal />
        <HelmetSeo title={titleState} desc={titleState} />
        <div id="bd" className="item">
          <Breadcrumb back={true} />
          <div className="col s12">
            <div style={{ marginTop: "5px" }}>
              <div className="item-main">
                <div id="mainGridDiv" className="item-top-row row">
                  <div id="leftGrid">
                    <ImageCarousel
                      productImageSwitch={productImageSwitchState}
                    />
                    <ShareButtons />
                  </div>
                  <div id="rightGrid">
                    <div id="titleGrid">
                      <div
                        id="js-item-title-267578"
                        className="regularTitle title itemTitle itemTitleMobile"
                        dangerouslySetInnerHTML={{
                          __html: htmlDecode(titleState)
                        }}
                      ></div>
                      <ReviewBar
                        avgRating={avgRating}
                        starFilter={starFilter}
                        setStarFilter={setStarFilter}
                        reviewsContainerRef={reviewsContainerRef}
                      />
                      <WishListBar />
                      <ItemCode />

                      <div className="col-xs-12 product-page-seller-info mobile-fs-16">
                        <div className="inStock-container">
                          {priceState && priceState.invs ? (
                            <div className="inStock-wrapper">
                              <label>{translate("js.item.stock")}:</label>
                              &nbsp;
                              <span style={{ fontWeight: 400 }}>
                                {priceState.invs[0].instock}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <p>
                          <strong>{translate("js.item.available")}: </strong>
                          <span id="buyBoxDistName">
                            {supplierInfoState.name}
                          </span>
                        </p>
                        <p>
                          <strong>{translate("js.item.location")}: </strong>
                          <span id="buyBoxDistLoc">
                            {supplierInfoState.city}
                          </span>
                        </p>

                        <div className="product-page-seller-rating">
                          <span className="product-page-seller-title">
                            {translate("js.item.sellerrating")}:&nbsp;
                          </span>
                          <div id="buyBoxDistRate">
                            <div className="dist-item-rating">
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <i className="material-icons star-icon">
                                star_border
                              </i>
                              <div className="distReviewCount">(0)</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Attributes />
                      <CheckBoxAddons />
                      {/*                      <Marketplaces />*/}
                    </div>
                    <AddToCartBox
                      handleEnquiryModalOpenClicked={
                        handleEnquiryModalOpenClicked
                      }
                    />
                  </div>
                  <ConfirmDeliveryOptions />
                  <CartValidationErrors />
                  <OutOfStockError />
                  <AddedToCartModal />
                </div>
              </div>
            </div>
          </div>
          <Accessories />
          <VendorList
            handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
          />
          <OtherInfoTab />
          <Reviews
            avgRating={avgRating}
            setAvgRating={setAvgRating}
            starFilter={starFilter}
            setStarFilter={setStarFilter}
            reviewsContainerRef={reviewsContainerRef}
            reviewsWithImages={reviewsWithImages}
            setReviewsWithImages={setReviewsWithImages}
          />
          <RecentlyViewedItems />
          <GoBackButton standalone={true} />
          <Footer />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <HelmetSeo title="No Product" desc="No Product found on this name!" />

        <div id="bd" className="item">
          <div className="col s12">
            <Loading />
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
};

export default ProductPage;
