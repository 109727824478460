import React, { useState, useContext, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { PREVIEW, PROJECT_LINK, VID } from "../project-config.js";
import history from "../history.js";
import NavMenu from "./NavMenu.jsx";
import Logo from "../assets/img/Logo.png";
import LogoMobile from "../assets/img/demoLogo_mobile.png";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "../components/AC-MiniCart/MiniCart.jsx";

import { cookieWindowShow } from "../../src/redux/actions/loginActions";
import CookieDialog from "../components/AC-Cookie/CookieDialog";
import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import WishList from "../components/AC-WishList/components/WishListCustomModal.jsx";
import LanguageSelect from "../i18n/components/LanguageSelect";
import { I18nContext } from "../i18n/index";
import capitalize from "../functions/capitalize";

import Location from "../assets/img/location.png";

import Async from "react-code-splitting";

import Bag from "../assets/img/bag.png";
import More from "../assets/img/More.png";

const SearchBar = () => <Async load={import("./SearchHelper")} />;

export default function MenuTopBar() {
  const dispatch = useDispatch();
  const { translate } = useContext(I18nContext);

  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const suppliersBasketCountState = useSelector(
    state => state.sessionReducer.suppliersBasket.itemsCount,
    shallowEqual
  );

  const basketCountState = useSelector(
    state => state.sessionReducer.basket.itemsCount,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  let name;

  const toggleDrawerMini = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart close={() => toggleDrawerMini(side, false)} />
    </div>
  );

  const handleSubmit = evt => {
    evt.preventDefault();
    history.push(`${PREVIEW}/search/${name}`);
    /*alert(`Submitting Name ${name}`);*/
  };

  const handleIconClick = url => {
    history.push(`${PREVIEW}/${url}`);
  };

  const handleLoginIconClicked = event => {
    event.stopPropagation();
    console.info(event.target);
    if (loginNameState == "") {
      history.push(
        langCode !== "en"
          ? PREVIEW + "/" + langCode + "/login"
          : PREVIEW + "/login"
      );
    } else {
      setLoginModalOpened(true);
    }
  };

  // Lee
  // ? redux ?? cookieWindowState(null)
  // ???? cookie dialog
  let cookieWindowState = useSelector(
    state => state.loginReducer.cookieWindow,
    shallowEqual
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    // let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
    /*   console.info("handleCatChange", cid, cat, parents);
    if (cat.includes("&amp;")) {
      catName = cat.replace("&amp;", "&");
    }

    const category = navCatsState.childs.filter(c => {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    //dispatch(changeCategoryName(catName));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    } */
  };

  useEffect(() => {
    // (in Redux) cookieWindow = cookieAcceptance(null)    (in local)

    let decision = localStorage.getItem("cookieAcceptance");
    if (decision) {
      dispatch(cookieWindowShow(decision.isAccept));
    }
  });

  // ??handler???
  // (in Redux) cookieWindow = true
  const changeCookieInRedux = () => {
    dispatch(cookieWindowShow(true));
  };

  // ??handler???
  // (in local)   cookieAcceptance = {"date": , "consent": , "isAccept": }
  const acceptCookieHandler = () => {
    let consent = {
      date: new Date(),
      consent: "Cookies accepted.",
      isAccept: true
    };

    localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
  };

  const acceptCookieAndCloseWindow = () => {
    changeCookieInRedux();
    acceptCookieHandler();
  };

  return (
    <>
      <nav
        className="nav-extended"
        style={{ background: "#fff", padding: "4px 0px" }}
      >
        <div className="nav-wrapper">
          <div className="header-container" style={{ height: "auto" }}>
            {isMobileState ? (
              <React.Fragment>
                <div className="icon-container">
                  <div className="icon-wrapper">
                    <i
                      open={navMenuOpen}
                      className="material-icons mobile-nav-icon"
                      onClick={toggleDrawer()}
                    >
                      <span></span>
                    </i>
                  </div>

                  <div className="logo-container">
                    <Link
                      to={
                        langCode !== "en"
                          ? `${PREVIEW}/${langCode}/`
                          : PREVIEW + "/"
                      }
                      className="brand-logo logo-i"
                    >
                      <img
                        src={isMobileState ? LogoMobile : Logo}
                        alt="Open For Business Marketplace Logo"
                        className="img-responsive"
                      />
                    </Link>
                  </div>
                </div>
                <div className="mobile-nav-bar-container" open={navMenuOpen}>
                  <div
                    id="nav-menu-drawer"
                    className={
                      langCode === "ar"
                        ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                        : "nav-wrapper actual-nav scroll-bar-thin-style"
                    }
                  >
                    <div className="nav-content-wrapper">
                      <div className="search-bar-container">
                        <div className="search-bar-wrapper">
                          <SearchBarMobile setNavMenuOpen={setNavMenuOpen} />
                        </div>
                      </div>
                      <ul className="staticMenu">
                        <li style={{ color: "#2aa841" }}>
                          <Link
                            onClick={() => {
                              if (isMobileState) {
                                setNavMenuOpen(!navMenuOpen);
                              }
                            }}
                            className="menuCat1"
                            to={
                              langCode !== "en"
                                ? `${PREVIEW}/${langCode}/`
                                : PREVIEW + "/"
                            }
                          >
                            {translate("Home").toUpperCase()}
                          </Link>
                        </li>
                        {navCatsState.childs.map(cat => {
                          let catName = cat.name.replace("&amp;", "&");
                          let tempUrl = cat.URL;
                          tempUrl = tempUrl;

                          tempUrl = tempUrl.replace(`${langCode}/shop`, "shop");
                          return (
                            <li key={cat.cid}>
                              <Link
                                onClick={() =>
                                  handleCategoryChange(
                                    cat.cid,
                                    catName,
                                    [[catName, cat.cid, cat.URL]],
                                    cat.longdesc
                                  )
                                }
                                className="menuCat"
                                style={{
                                  textTransform:
                                    catName != "by Brand" &&
                                    catName != "by Style"
                                      ? ""
                                      : null
                                }}
                                to={
                                  langCode !== "en"
                                    ? PREVIEW + "/" + langCode + "/" + tempUrl
                                    : PREVIEW + "/" + tempUrl
                                }
                              >
                                {console.info("tempUrl", tempUrl)}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: catName
                                  }}
                                />
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <ul className="staticMenu">
                        <li>
                          <a
                            href="https://www.avetticommerce.com/"
                            target="_blank"
                          >
                            {translate("vm.footer_about").toUpperCase()}
                          </a>
                        </li>
                        {/*<li>
                          <Link
                            onClick={toggleDrawer()}
                            to={PREVIEW + "/login"}
                          >
                            {translate("js.header.login").toUpperCase()}
                          </Link>
                        </li>
                          <li>
                    <a
                      href={`${PROJECT_LINK}/myaccount.html?mode=customerinfo&vid=${VID}`}
                    >
                      {translate("js.header.login").toUpperCase()}
                    </a>
                </li> 
                        <li>
                          <a
                            href={`${PROJECT_LINK}/basket.html?vid=${VID}&iu=${languageState}`}
                          >
                            {translate("js.header.cart").toUpperCase()}
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${PROJECT_LINK}/signin.html?vid=${VID}&mt=1&iu=${languageState}`}
                          >
                            {translate("Myfavourites").toUpperCase()}
                          </a>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            <div
              className="logo-container"
              className={
                isMobileState ? "hidden logo-container" : "logo-container"
              }
            >
              <Link
                to={
                  langCode !== "en" ? `${PREVIEW}/${langCode}/` : PREVIEW + "/"
                }
                className="brand-logo logo-i"
              >
                <img
                  src={isMobileState ? LogoMobile : Logo}
                  alt="Open For Business Marketplace Logo"
                  className="img-responsive"
                />
              </Link>
            </div>

            <div className="nav-icons-container">
              <div className="nav-icons-container">
                <div className="icon-container">
                  <LanguageSelect />
                </div>

                <WishList />
                {/* <div className="icon-container">
                  <div className="icon-wrapper">
                    <img src={WishListIcon} className="iconInfo1" />
                  </div>
                </div> */}

                <div
                  id="cart-icon-btn"
                  className="icon-container"
                  onClick={
                    langCode === "ar"
                      ? toggleDrawerMini("left", true)
                      : toggleDrawerMini("right", true)
                  }
                >
                  <div className="icon-wrapper">
                    <img src={Bag} />
                    <span
                      className="minicart-new-count"
                      /*  style={
                        basketCountState + suppliersBasketCountState > 0
                          ? { backgroundColor: "red" }
                          : {}
                      } */
                    >
                      {basketCountState + suppliersBasketCountState}
                    </span>
                    {basketCountState + suppliersBasketCountState > 0 ? (
                      <span className="icon-item-count-text-mobile">
                        {basketCountState + suppliersBasketCountState}
                      </span>
                    ) : null}
                  </div>
                  <div className="icon-text">
                    {/* <span className="icon-title" style={{ display: "flex" }}>
                  
                </span> */}
                    <span className="icon-action-text">
                      {translate("js.header.cart")}
                    </span>
                  </div>
                </div>

                <Drawer
                  anchor={langCode === "ar" ? "left" : "right"}
                  open={langCode === "ar" ? state.left : state.right}
                  onClose={
                    langCode === "ar"
                      ? toggleDrawerMini("left", false)
                      : toggleDrawerMini("right", false)
                  }
                >
                  {langCode === "ar" ? sideList("left") : sideList("right")}
                </Drawer>

                <div
                  className="icon-container login-icon-container"
                  // onClick={event => handleLoginIconClicked(event)}
                >
                  <Profile
                    modalOpenedByIcon={loginModalOpened}
                    setModalOpened={setLoginModalOpened}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className={isMobileState ? "nav-extended hidden" : "nav-extended"}
        style={{
          boxShadow: "none",
          background: "#c2ff61",
          padding: "5px 0px",
          borderTop: "2px solid #000"
        }}
      >
        <div className="nav-wrapper">
          <div className="header-container">
            {cookieWindowState === null &&
            !window.location.pathname.includes("cookiepage") ? (
              <CookieDialog
                destination={
                  langCode !== "en"
                    ? `${PREVIEW}/${langCode}/cookiepage`
                    : PREVIEW + "/cookiepage"
                }
                acceptCookieCloseDialog={acceptCookieAndCloseWindow}
              />
            ) : null}

            <NavMenu />
            <SearchBar />
          </div>
        </div>
      </nav>
    </>
  );
}
