import React from "react";
import { Link } from "react-router-dom";

import { I18nContext } from "../i18n/index";
export default function AnchorElement({ attributes, ...props }) {
  const { translate } = React.useContext(I18nContext);
  const renderInnerHTML = () => {
    return props.image ? <img src={props.image} /> : translate(props.text);
  };
  if (attributes.to) {
    return <Link {...attributes}>{renderInnerHTML()}</Link>;
  }
  return <a {...attributes}>{renderInnerHTML()}</a>;
}
