import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { toggleWishListAction } from "../../../../redux/actions/wishListActions.js";

import { I18nContext } from "../../../../i18n/index";
import { PREVIEW } from "../../../../project-config.js";
import "./Styles/WishListBar.css";

const WishListBar = () => {
  const dispatch = useDispatch();
  const { translate, langCode } = React.useContext(I18nContext);

  const [favouriteState, setFavouriteState] = useState("favorite_border");

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const productState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const itemDetail = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const priceDetail = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  let isItemWishlisted =
    wishListState.length > 0
      ? wishListState.some(w => w.id == itemDetail.itemId)
      : false;

  const toggleWish = e => {
    e.preventDefault();
    let imageUrl = itemDetail.image;
    if (imageUrl.includes("sls3.avetti.ca")) {
      imageUrl = imageUrl.replace(
        "sls3.avetti.ca",
        "demob2b2cs3.avetti.ca/store"
      );
    }
    let urlProduct = window.location.pathname;

    urlProduct = urlProduct.replace("/preview", "");
    if (urlProduct.includes(`/${langCode}/`)) {
      urlProduct = urlProduct.replace(`/${langCode}`, "product");
    }
    let price = {
      value: {
        integer: Math.floor(priceDetail.prices[0].price_1),
        decimal: Math.round((priceDetail.prices[0].price_1 % 1) * 100)
      }
    };

    dispatch(
      toggleWishListAction(
        itemDetail.itemId,
        itemDetail.code,
        itemDetail.title,
        itemDetail.shortdesc,
        productState.currency_sign,
        imageUrl,
        price,
        urlProduct,
        wishListState
      )
    );

    // id,
    // title,
    // desc,
    // currency_sign,
    // image,
    // price,
    // url,
    // wishListState
  };

  const handleToggleWishlistIcon = e => {
    isItemWishlisted
      ? setFavouriteState("favourite_border")
      : setFavouriteState("favourite");
    toggleWish(e);
  };

  return (
    <div className="col-xs-12" style={{ paddingLeft: "0px" }}>
      <p className="wishlist-bar--bar">
        {translate("js.item.wishlist.add")}:
        <span id="buyBoxWishlist" style={{ paddingLeft: "20px" }}>
          <span
            className="wishlistHeart"
            title={translate("js.item.wishlist.add")}
          >
            <span style={{ cursor: "pointer" }}>
              <i
                className="material-icons add-icon"
                onMouseEnter={() => setFavouriteState("favorite")}
                onMouseLeave={() => setFavouriteState("favorite_border")}
                onClick={e => {
                  handleToggleWishlistIcon(e);
                }}
                style={
                  isItemWishlisted || favouriteState === "favorite"
                    ? { color: "red" }
                    : null
                }
              >
                {isItemWishlisted || favouriteState === "favorite"
                  ? `favorite`
                  : `favorite_border`}
              </i>
            </span>
          </span>
        </span>
      </p>
    </div>
  );
};

export default WishListBar;
