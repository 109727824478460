import React, { useState, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import ItemCard from "./components/ItemCard";
import Slider from "react-slick";
import { I18nContext } from "../../../../i18n";

import {
  updateSelectedCheckboxItems,
  fetchingCheckboxItemPriceInventory
} from "../../../../redux/actions/productAction";

const Accessories = () => {
  const dispatch = useDispatch();

  const { translate } = useContext(I18nContext);

  const accessoriesState = useSelector(
    state => state.productReducer.checkboxItems,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  // const addItem = ()

  const handleSelect = (id, title, imageUrl, price, event) => {
    const item = {
      id: id,
      title: title,
      imageUrl: imageUrl,
      price: price
    }
    let mode = "remove";
    if(event.target.checked) 
      mode = "add"

    // dispatch(updateSelectedCheckboxItems(item, mode));
    dispatch(fetchingCheckboxItemPriceInventory(item, mode));
  };

  if (accessoriesState.length > 0) {
    // return (
    //   <div className="recently-viewed-items-container">
    //     <h1
    //       className="no-select recently-viewed-title"
    //       onClick={() => setRecentlyViewedCollapsed(!recentlyViewedCollapsed)}
    //     >
    //       {/* {translate("accessories.title")} */}
    //       Addons
    //       <i className="material-icons">
    //         keyboard_arrow_{recentlyViewedCollapsed ? "down" : "up"}
    //       </i>
    //     </h1>
    //     <Slider {...settings}>
    //       {accessoriesState.map(item => (
    //         <div key={item.id} className="owl-item col-xs-12">
    //           <ItemCard {...item} />
    //         </div>
    //       ))}
    //     </Slider>
    //   </div>
    // );
    return (
      <div >
          {accessoriesState.map(item => (
            <div  key={item.id}>
              {/* <input id={item.id} className={classes.cBox} type="checkbox" onChange={() => setChecked(checked => !checked)}></input> */}
              {/* <input id={item.id} className={classes.cBox} type="checkbox" onChange={event => handleClickOnProductCard(item.id, item.title, item.imageUrl, item.price)}></input> */}
              <input id={item.id}  type="checkbox" onChange={event => handleSelect(item.id, item.title, item.imageUrl, item.price, event)}></input>
              <label for={item.id}>Add {item.title} for ${parseFloat(item.price).toFixed(2)}</label> 
            </div>
          ))}
      </div>
    );
  } else {
    return null;
  }
};

export default Accessories;
